import React from 'react';


const ScoreCard = ({ title, subtitle = "", value, valueCompare = "", textColor,notDoneTask }) => {
  return (
    <div className="dashboard__card score-card">
      <div className="dashboard__card__title">{title}</div>
      <div className='score-card__values'>
        <span className={`score-card__value ${textColor}`}>{value}</span>
        {valueCompare !== "" && <span className={`score-card__value_compare ${textColor}`}>/ {valueCompare}</span>}
      </div>
      {subtitle !== "" && <p className="dashboard__card__subtitle">{subtitle}</p>}
    </div>
  );
};

export default ScoreCard;
