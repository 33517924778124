import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import messages from '../../utils/meesage';
import FilesService from '../../services/files.service';
import { validateFiles } from '../../utils/validations';
import { setLoading } from '../../feature/loading.slice';
import { setMessage } from '../../feature/message.slice';
import Error from '../../components/common/Error';
const NewChatModal = ({ show, handleClose, messageChange,clientDetails, subjectChange, sendMessage,handleSelectChangeCompose,subject,newmessage,selectedClientCompose}) => {
  // console.log(subject)
  const isSendButtonDisabled = !subject || !newmessage || !selectedClientCompose;
 
  const isLoading = useSelector((state) => state.loading.loading);
  const loadingmessage= useSelector((state) => state.message.message);
 
  const [uploading, setUploading] = useState(false);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [fileuploadingError, setfileuploadingError] = useState(null);
  const [fileuploadError,setfileuploadError]=useState("")
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    // Validate the selected files
    const validationErrors = validateFiles(selectedFiles);
  
    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => {
        console.error(`Error with file "${error.fileName}": ${error.reason}`);
      });
  
      
      setfileuploadError(`Error: ${validationErrors.map(error => `${error.fileName}: ${error.reason}`).join(', ')}`)
      
      
    }
  
    // If no validation errors, proceed with adding the files to the state
    setFiles([...files, ...selectedFiles]);
  };



  let fileDetailsArray = [];

  const saveFile=async(fileDetails)=>{

 
    
    const data={
      clientId: selectedClientCompose,
      files: fileDetails,
    }
    
            try{
          
              var response = await FilesService.savefile(data);
              // uploadFilehandleClose()
           
            }catch(err){
          console.log(err)
            }
  
  
            return response
          }
  
  
    const handleFileUpload = async (file, clientId) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('clientId', clientId);
  
  try {
     
        setUploading(true);
  
   const Filesdetails= await FilesService.UploadFiles(formData);
           
  
  
  fileDetailsArray.push({
    originalFileName: Filesdetails.originalFileName,
    uniqueFileName: Filesdetails.uniqueFileName,
    visibility: 1
  });
  
  
  
        setUploadIndex((prevIndex) => prevIndex + 1); 
        setUploading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        setfileuploadingError(error.response.data.errors[0].msg)
        setUploading(false);
      }
    };
  







  const sendnewMessage=async()=>{
try{


  dispatch(setLoading(true));
  dispatch(setMessage(messages.loading));
    if (uploadIndex < files.length  && !uploading&& !fileuploadingError) {
         for (let i = 0; i < files.length; i++) {
          await handleFileUpload(files[i], selectedClientCompose);  
           }
         }
     
        
         let fileIds = [];
         if(fileDetailsArray.length>0){
         var saveFiles= await saveFile(fileDetailsArray)
     
     fileIds = saveFiles.dbEntries.map((entry) => entry.id);

      
       }


       sendMessage(fileIds)




}catch(err){
  console.log(err)
} finally {
  dispatch(setLoading(false));

}
  }








  return (
    <Modal show={show} onHide={handleClose} animation={true}>
      <Modal.Header closeButton>
        <Modal.Title>Send New Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>

      <div className='company-filter mb-3'>
            <select className="form-select form-select-sm" aria-label="Default select example" onChange={handleSelectChangeCompose}>
              <option selected disabled>By Corporation</option>
              <option value="all">All</option>

              {clientDetails.map((client) => (
                <option key={client.id} value={client.id}>{client.name}</option>
              ))}
            </select>
          </div>
      <Form.Floating className='mb-3'>
          
          <Form.Control type="text" onChange={subjectChange} />
          <Form.Label>Subject</Form.Label>
        </Form.Floating>
     

        <Form.Floating className='mb-3 '>
          <Form.Control rows={6} as="textarea" onChange={messageChange} style={{ height: '150px' }} />
          <Form.Label>Message</Form.Label>
        </Form.Floating>
  
        <Form.Group className='mb-3'>
          <Form.Label>Attach Files</Form.Label>
          <Form.Control type="file" multiple  onChange={handleFileSelect} />
          {fileuploadError && <Error error={fileuploadError}/>}
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        {/* <Button variant="primary" className='btn-sm' onClick={sendMessage}  disabled={isSendButtonDisabled}>Send Message</Button> */}
        <Button variant="primary" className='btn-sm' onClick={sendnewMessage}  disabled={isSendButtonDisabled ||isLoading }> {isLoading?(loadingmessage):("Send Message")}</Button>

      </Modal.Footer>
    </Modal>
  );
};

export default NewChatModal;
