import React, { useEffect, useState } from 'react'
import { Button, Form, InputGroup } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';

import { useSelector } from 'react-redux';
import chatService from '../../services/chat.service';
import { useLocation, useParams } from 'react-router-dom'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faPaperclip, faChevronLeft ,faChevronRight } from '@fortawesome/free-solid-svg-icons';


const ChatsandCoversation = () => {
  const { id, page } = useParams();
  const [Threads, setThreads] = useState([])
  // console.log(Threads)
  const [ThreadsId, setThreadsId] = useState(id)
  const [UserId, setUserId] = useState("")
  const [recipeintId, setrecipeintId] = useState("")
  const [tasks, setTasks] = useState({});
  // console.log(tasks)
  const [clientIds, setclientId] = useState("")
  const [Newmessage, setNewmessage] = useState("")
  const [message, setMessage] = useState([])
  // console.log(message)
  const [subject, setSubject] = useState("")
  const [recipeintName, setrecipeintName] = useState("")
  const [searchKeywords, setsearchKeywords] = useState("")
  const [currentPage, setcurrentPage] = useState(page)
  // console.log(currentPage)

  const [currentSet, setCurrentSet] = useState(1);

  const [TotalPage, setTotalPage] = useState("")
  // console.log(TotalPage)
  const clientDetails = useSelector((state) => state.client.client);
  const clientId = useSelector((state) => state.client.clientId);
  const findThreadById = (threads, id) => {
    return threads.find(thread => thread.thread.id == id);
  };
  const updateLastSeen = async () => {
    const data = {
      user_id: UserId
    }
    try {
      const LastSeen = await chatService.lastSeen(ThreadsId, data)
      // console.log(LastSeen)
      fetchThreads()
    } catch (err) {
      console.log(err)
    }
  }

  const fetchThreads = async () => {
    const userId = localStorage.getItem('userId');
    const client = localStorage.getItem('client');
    const clientids = JSON.parse(client);
    setUserId(userId)
    const data = {
      userId: userId,
      clientId: clientids,
      searchKeyword: searchKeywords,
      page: currentPage
    }

    try {
      const Threads = await chatService.getThread(data)
      // console.log(Threads)
      setThreads(Threads.threads)
      setTotalPage(Threads.totalPages)
      setcurrentPage(Threads.currentPage)
      const foundThread = findThreadById(Threads.threads, ThreadsId);
      // setTasks(JSON.parse(foundThread.thread.tasks || '[]'));

      setclientId(foundThread.thread.client_id)
      setSubject(foundThread.thread.subject)
      // console.log(foundThread)
      const userid = foundThread.thread.participants.filter(participant => participant.id != userId);
      setrecipeintId(userid[0].id)
      setrecipeintName(userid[0].name)
      // console.log(userid[0].id)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchThreads()
    updateLastSeen()
  }, [clientId, ThreadsId, UserId, searchKeywords, currentPage])


  const isThreadUnreadForUser = (thread) => {
    return thread.participants.some(p => p.id === parseInt(UserId) && p.last_read === null);
  };

  const formatTime = (dateTimeString) => {
    const options = {
      hour: 'numeric',
      minute: 'numeric'
    };
    const dateTime = new Date(dateTimeString);
    return dateTime.toLocaleTimeString('en-US', options);
  };
  const getLastMessageTime = (thread) => {
    if (thread.thread.updated_at) {
      return formatTime(thread.thread.updated_at);
    } else {
      return '';
    }
  };

  const fetchThreadsById = async (ThreadsId) => {
    try {
      console.log(ThreadsId)
      const Threads = await chatService.getThreadsByid(ThreadsId)
      console.log(Threads)
      setTasks(prevTasks => ({
        ...prevTasks,
        [ThreadsId]: Threads.thread.tasks || []
      }));
      
     
      setMessage(Threads.message)
      // console.log(Threads)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchThreadsById(ThreadsId)

  }, [])
  const handleRowClick = (thread) => {
    updateLastSeen()
    setThreadsId(thread.thread.id)
    fetchThreadsById(thread.thread.id)
    setclientId(thread.thread.client_id)
    setSubject(thread.thread.subject)
    const userid = thread.thread.participants.filter(participant => participant.id != UserId);
    setrecipeintId(userid[0].id)
    setrecipeintName(userid[0].name)
  };


  const handlemessageChange = async (content) => {
    const cleanedContent = content.replace(/<p><br><\/p>/g, '');
    setNewmessage(cleanedContent)
  }
  const sendMessage = async () => {
    setNewmessage("")

    const data = {
      user_id: UserId,
      recipient_id: recipeintId,
      subject: subject,
      body: Newmessage,
      client_id: clientId
    }
    try {
      const sendMessage = await chatService.SendMessage(ThreadsId, data)
      fetchThreadsById(ThreadsId)
      fetchThreads()

    } catch (err) {
      console.log(err)
    }

  }
  const handleTaskChange = async (ThreadsId, index) => {
    const updatedTasks = [...tasks[ThreadsId]];
    const isTaskDone = !updatedTasks[index].taskDone;
    updatedTasks[index].taskDone = isTaskDone;
    updatedTasks[index].taskDoneTime = isTaskDone ? new Date().toISOString() : null;
    setTasks(prevTasks => ({
      ...prevTasks,
      [ThreadsId]: updatedTasks
    }));


    try {
      const updateTask = await chatService.updateTask(ThreadsId, { tasks: updatedTasks })
      console.log(updateTask)
    } catch (err) {
      console.log(err)
    }
  }
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [NewMessage, setNewMessage] = useState("")

  const [Newsubject, setNewSubject] = useState("")

  const messageChange = (e) => {
    setNewMessage(e.target.value)
  }


  const subjectChange = (e) => {
    setNewSubject(e.target.value)
  }


  const sendNewMessage = async () => {
    const data = {
      user_id: UserId,
      recipient_id: 9,
      subject: Newsubject,
      body: NewMessage,
      client_id: clientId
    }
    try {

      const newThread = await chatService.SendNewMessage(data)
      console.log(newThread)
      setThreadsId(newThread.thread.id)
      fetchThreadsById(newThread.thread.id)
      setShow(false)

    } catch (err) {
      console.log(err)
    }
  }


  const changeSearch = (e) => {
    setsearchKeywords(e.target.value)
  }


  const handlePageChange = (page) => {
    if (page >= 1 && page <= TotalPage) {
      setcurrentPage(page);
    }
  };


  const renderPagination = () => {
    const pages = [];
    const maxPagesToShow = 3;
    const totalSets = Math.ceil(TotalPage / maxPagesToShow);
    const startPage = (currentSet - 1) * maxPagesToShow + 1;
    const endPage = Math.min(currentSet * maxPagesToShow, TotalPage);

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <li key={i} className={`page-item ${currentPage == i ? 'active' : ''}`}>
          <a className="page-link" href="#!" onClick={() => handlePageChange(i)}>
            {i}
          </a>
        </li>
      );
    }
    return (
      <>
        {currentSet > 1 && (
          <li className="page-item">
            <a className="page-link" href="#!" onClick={() => {
              setCurrentSet(currentSet - 1);
              setcurrentPage((currentSet - 2) * maxPagesToShow + 1);
            }}>
              &laquo;
            </a>
          </li>
        )}
        {pages}
        {currentSet < totalSets && (
          <li className="page-item">
            <a className="page-link" href="#!" onClick={() => {
              setCurrentSet(currentSet + 1);
              setcurrentPage(currentSet * maxPagesToShow + 1);
            }}>
              &raquo;
            </a>
          </li>
        )}
      </>
    );
  };

  function getClientNameById(id) {
     
    const client = clientDetails.find(client => client.id === id);
    
    
    return client ? client.name : null;
  }
  return (
    <div className=''>
      <div className=" Chats_wrapper card-body">
        <div className='row '>
          <div className="first-container col-lg-4 col-md-12">
            <div className="container-header">
              <h2>Chats</h2>
              <Button variant="primary" className="btn btn-sm" onClick={handleShow}>
                New Chat
              </Button>
            </div>
            <hr />
            <Form.Control type="text" className="form-control" placeholder="Search Chats" onChange={changeSearch} />
            <div className="all-chats">
              {Threads.map((thread) => (
                <div className=' '>
                  <div
                    key={thread.thread.id}
                    className= {`${isThreadUnreadForUser(thread.thread) ? "unread" : "read"} p-3 onhover_bg`}
                    onClick={() => handleRowClick(thread)}
                
                  >

                    <div className='row justify-content-between align-items-center'>
                    <div className="col-1 active-icon">
                      <FontAwesomeIcon icon={faCircle} style={{ color: "#0E5FF5", fontSize: "10px" }} />
                    </div>
                      <div className='col-9'>
                      <p className="request-text">{thread.thread.subject}</p>
                    <span className="document-text">
                      <div dangerouslySetInnerHTML={{ __html: thread.thread.lastMessage }} />
                    </span>
                      </div>
                      <div className='col-2'>
                      {/* <FontAwesomeIcon  icon={faPaperclip} /> */}
                      <FontAwesomeIcon  className='paperclip-icon' icon={faPaperclip} />
                      

                      </div>

                    </div>
                
                    <div className="document-time-container">
                    {/* <FontAwesomeIcon className='paperclio-icon' icon={faPaperclip} /> */}

                    <div>
                    <span>{getClientNameById(thread.thread.client_id)} </span> <span>|</span> <span className="">Last Message by {thread.thread.participants.map((name) => (
                          name.sender && name.name
                        ))} </span>
                    
                    </div>
                      <span className="document-time">{getLastMessageTime(thread)}</span>
                    </div>
                  </div>
                  {/* <hr /> */}
                </div>
              ))}
             
          <div className=" pagination-main">
            <nav aria-label="Page navigation example">
              <ul className="pagination " >
                <div className='pt-1' >  <a href="#"><FontAwesomeIcon icon={faChevronLeft} /></a> </div>
                {renderPagination()}
                <div className='pt-1' > <a href="#"><FontAwesomeIcon icon={faChevronRight} /></a></div>
              </ul>
            </nav>
          </div>
            </div>
          </div>
          <div className="second-container">
            <div className="header">
              <h2>{subject}</h2>
              <hr />
            </div>
            <div className="chat_box">
              {message.map((msg) => (
                <div
                  key={msg.id}
                  className={`chat-message ${msg.user_id == UserId ? 'user-message' : 'other-message'}`}
                >
                  <div className="message-header">
                    <p className="message-sender">{msg.user_id == UserId ? 'You' : recipeintName}</p>
                    <span className="message-time">{new Date(msg.created_at).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</span>
                  </div>
                  <div className="message-body" dangerouslySetInnerHTML={{ __html: msg.body }} />
                </div>
              ))}
            </div>
            <div className="card card-style">
              <div className="new-message">
                <ReactQuill
                  value={Newmessage}
                  onChange={handlemessageChange}
                  placeholder="Type Message here..."
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ size: [] }],
                      ['bold', 'italic', 'underline'],
                      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    ],
                  }}
                  style={{ marginBottom: '10px', border: 'none' }}
                />
                <Button variant="outline-primary" className="btn btn-sm align-self-end send-button" onClick={sendMessage}>
                  Send
                </Button>
              </div>
            </div>
          </div>
          <div className="task-container third-container col col-lg-4 col-md-12  ">
            <h2 className="task-heading">Your Tasks</h2>
            <hr className="task-divider" />
            <div>
              <Form>
                {tasks[ThreadsId] && tasks[ThreadsId].map((task, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    id={`task-${index}`}
                    label={task.taskDetails}
                    className="text-black fw-semibold"
                    checked={task.taskDone}
                    onChange={() => handleTaskChange(ThreadsId, index)}
                  />
                ))}
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>New Chat</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex flex-column mb-3'>
            <label htmlFor="message" className="form-label">MESSAGE</label>
            <textarea id="message" name="message" className="form-control" onChange={messageChange}></textarea>
          </div>

          <div className='d-flex flex-column mb-3'>
            <label htmlFor="message" className="form-label">Subject</label>
            <textarea id="subject" name="subject" className="form-control" onChange={subjectChange}></textarea>
          </div>
          <div className='d-flex flex-column mb-3'>
            <label htmlFor="attachFiles" className="form-label ">ATTACH FILES</label>
            <input type="file" id="attachFiles" name="Browse Files" className="form-control" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendNewMessage}>
            Send Message
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default ChatsandCoversation