import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/auth/`;
const forgetPassword = async (data ) => {

    const res = await axios.post(API_URL + "forgotPassword",data)

    return res.data;

}
const resetPassword = async (data,token ) => {

    const res = await axios.post(API_URL + "resetPassword",data, {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    })

    return res.data;

}

const LoginWithOtp = async (data) => {

    const res = await axios.post(API_URL + "loginOtp",data, {
        headers: {
            'Content-Type': 'application/json',
         
        }
    })

    return res.data;

}

const VerifyOtp = async (data) => {

    const res = await axios.post(API_URL + "verifyOtp",data, {
        headers: {
            'Content-Type': 'application/json',
         
        }
    })

    return res.data;

}

const authService={
    
    forgetPassword,resetPassword,LoginWithOtp,VerifyOtp

}

export default authService