import React, { useEffect } from 'react'
import { useState, useRefuseRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AddressService from '../../../services/address.service';
import { useSelector } from 'react-redux';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';

const CompanyDataAddress = ({Address}) => {

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    streetName: Yup.string().required('Street Name & Number is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.number().required('Zip is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.number().required('State is required')
  });
  const [showViewPopup, setViewPopup] = useState(false);
  const[AddressDetails,setAddressDetails]=useState([])
  const [AddressAllDetails,setAddressAllDetails]=useState([])
  
  const viewHandleClose = () => setViewPopup(false);
  const viewHandleShow = (AddressDetails) => {
    setAddressAllDetails(AddressDetails)
    
    
    setViewPopup(true)};


  // const clientId = useSelector((state) => state.client.clientId);
  // const fetchAddressDetails=async()=>{

  //   try{
  
  //     const AddressDetails=await AddressService.getAddress(clientId)
  //     setAddressDetails(AddressDetails.data)
  //     console.log(AddressDetails)
  //   }catch(err){
  //   console.log(err)
  //   }
    
  //   }
    
    
  //   useEffect(()=>{
  //     fetchAddressDetails()
  //   },[clientId])
    
  return (

    <>
   { Address.length!=0&&
   
   <Card className="moreinfo-card">
  <div>
    <div className="d-flex justify-content-between py-1">
      <h5 className="fw-bold card-title">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="card-icon" /> Addresses
      </h5>
      {/* <Button variant="outline-primary" className="py-2 btn-sm" onClick={handleShow}>ADD NEW</Button> */}
    </div>
    <div className="card-divider"></div>
    

    {Address.length > 0 && Address.map((AddressDetails) => (
      <div className="d-flex justify-content-between py-3 card-details" key={AddressDetails.full_address}>
        
        {/* Left side: Type */}
        <div className="card-info half-width">
          <h6 className="mb-1">Type:</h6>
          <p className="text-black mb-0">{AddressDetails.type}</p>
        </div>
        
        {/* Right side: Full Address */}
        <div className="card-info half-width">
          <h6 className="mb-1">Full Address:</h6>
          <p className="text-black mb-0">{AddressDetails.full_address}</p>
        </div>
        
      </div>
    ))}
  </div>
</Card>
      
      }
    

      {/* <Modal show={showViewPopup} onClick={viewHandleClose} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>View Address Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" py-2 px-3">
            <div className="d-flex justify-content-start ">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Type</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>Texas Workforce Commission</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  ></span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  ></p>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-start mb-4">
              <div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}  >Address</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{AddressAllDetails.address_line_1}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}  >Street Name & Number</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{AddressAllDetails.address_line_2}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >City</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{AddressAllDetails.city}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >Zip</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{AddressAllDetails.zip}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >Country</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{AddressAllDetails.country}</p>
              </div>
              <div className="w-25">
                <span className='text-black' style={{ fontSize: '15px' }}  >State</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{AddressAllDetails.state}</p>
              </div>

           { AddressAllDetails.dba!=null&&  <div className="w-25">
                <span className='text-black' style={{ fontSize: '15px' }}  >Dba</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{AddressAllDetails.dba&&AddressAllDetails.dba.name}</p>
              </div>}
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

    </>


  )
}

export default CompanyDataAddress