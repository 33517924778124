import React, { useEffect } from 'react'
import { Card, Accordion, } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import TaxAccountService from '../../../services/taxAccount.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoiceDollar } from '@fortawesome/free-solid-svg-icons'; // Import tax account icon

const CompanyDataAccount = ({ tax_accounts }) => {



  const validationSchema = Yup.object().shape({
    userid: Yup.number().required('User id is required'),
    password: Yup.number().required('Password is required'),
    companyid: Yup.number().required('Company Id is required'),
    notes: Yup.string().required('Notes is required'),
  });

  const [showAddpopup, setAddpopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [TaxAccountDetails, setTaxAccountDetails] = useState([])
  const [TaxAccountAllDetails, setTaxAccountAllDetails] = useState([])
  // console.log("TaxAccountAllDetails",TaxAccountAllDetails)
  const [AccountType, setAccountType] = useState([])
  const handleClose = () => setAddpopup(false);
  const handleShow = (taxAcoount) => {

    setTaxAccountAllDetails(taxAcoount)

    setAddpopup(true)
  };
  const editHandleClose = () => setShowEditPopup(false);
  const editHandleShow = () => setShowEditPopup(true);


  // // const clientId = useSelector((state) => state.client.clientId);
  // const fetchTaxAccountDetailsDetails=async()=>{

  //   try{

  //     const TaxAccountDetails=await TaxAccountService.getTaxAccount(clientId)

  //     setTaxAccountDetails(TaxAccountDetails)
  //     // console.log(TaxAccountDetails)
  //   }catch(err){
  //   console.log(err)
  //   }

  //   }

  //   const fetchAccountType=async()=>{
  //     try{
  //       const accountType=await TaxAccountService.getTaxAccountType()
  //       console.log(accountType)
  //   setAccountType(accountType)
  //     }catch(err){
  //       console.log(err)
  //     }
  //   }

  //   useEffect(()=>{
  //     fetchTaxAccountDetailsDetails()
  //     fetchAccountType()
  //   },[clientId])


  const getTitleById = (id) => {

    const accountType = AccountType.find(account => account.id == id);
    // console.log(accountType)
    return accountType ? accountType.title : 'Account type not found';
  };

  return (
    <>
      {tax_accounts.length != 0 &&

        <Card className="moreinfo-card">
          <div>
            <div className="d-flex justify-content-between py-1">
              <h5 className="fw-bold card-title">
                <FontAwesomeIcon icon={faFileInvoiceDollar} className="card-icon" /> Tax Accounts
              </h5>
            </div>
            <div className="card-divider"></div>
            
            {tax_accounts && tax_accounts.map((taxAccount, index) => (
              <div
                className={`d-flex flex-wrap py-3 gap-2 card-details ${tax_accounts.length === 1 || index === tax_accounts.length - 1 ? 'no-border' : ''}`}
                key={taxAccount.account_no}
              >
                <div className="card-info half-width">
                  <h6 className="mb-1">Account Type:</h6>
                  <p className="text-black mb-0">{taxAccount.account_type}</p>
                </div>
                <div className="card-info half-width">
                  <h6 className="mb-1">Account Number:</h6>
                  <p className="text-black mb-0">{taxAccount.account_no}</p>
                </div>
              </div>
            ))}
          </div>
        </Card>

      }


      {/* <Modal show={showAddpopup} onClick={handleClose} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Create Tax Accounts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className=" py-2 px-3">
           <div className="d-flex justify-content-start mb-4">
             <div className="w-100">
               <span className='text-black' style={{ fontSize: '15px' }}>Account type</span>
               <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{getTitleById(TaxAccountAllDetails.account_type)}</p>
             </div>    

                <div className="w-100">
               <span className='text-black' style={{ fontSize: '15px' }}>Taxpayer Number</span>
               <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{TaxAccountAllDetails.account_no}</p>
             </div>          

             
                <div className="w-100">
               <span className='text-black' style={{ fontSize: '15px' }}>Franchise Tax PIN Number</span>
               <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{TaxAccountAllDetails.pin}</p>
             </div>     

       {  TaxAccountAllDetails.dba&&    <div className="w-100">
               <span className='text-black' style={{ fontSize: '15px' }}>Franchise Tax PIN Number</span>
               <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{TaxAccountAllDetails.dba&&TaxAccountAllDetails.dba.name}</p>
             </div>}  
           </div>

         </div>
        </Modal.Body> */}
      {/* <Modal.Footer className='justify-content-start'>
          <Button type="submit" className="btn btn-primary" onClick={handleClose}>
            Submit
          </Button>
          <Button variant="outline-primary" onClick={handleClose}>
            Cancel
          </Button>
        </Modal.Footer> */}
      {/* </Modal> */}
      {/* DBAS on click edit form */}


    </>



















  )
}

export default CompanyDataAccount