import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import FilesService from '../../services/files.service';
import { useDispatch, useSelector } from 'react-redux';
import { validateFileUpload } from '../../utils/validations';
import Error from '../../components/common/Error';
import { setLoading } from '../../feature/loading.slice';
import messages from '../../utils/meesage';
import { setMessage } from '../../feature/message.slice';
import { getFileIcon } from '../../utils/fileIcon'; // Assuming you have this for file icons

const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'xlsx', 'docx', 'pptx']; // Define allowed extensions
const maxSize = 5 * 1024 * 1024; // Max size of 5MB

const Document_file_Upload = ({ uploadFilehandleClose, getFiles }) => {
  const loadingmessage = useSelector((state) => state.message.message);
  const isLoading = useSelector((state) => state.loading.loading);
  const currentFolderId = useSelector((state) => state.folder.currentFolderId);

  const dispatch = useDispatch();
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [invalidFiles, setInvalidFiles] = useState([]); // Track invalid files
  const [fileDetails, setFileDetails] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [fileuploadingError, setfileuploadingError] = useState(null);
  const clientDetails = useSelector((state) => state.client.client);
  const [selectedClients, setSelectedClients] = useState("");
  console.log("selectedClients",selectedClients)

  const activeClient = useSelector((state) => state.folder.activeClient
);

// console.log("activeClient",activeClient)

  const handleFileSelect = (event) => {
    if(!selectedClients) {
      alert("Please choose the corporate first");
    }
    const selectedFiles = Array.from(event.target.files);
    const validFiles = [];
    const invalidFilesTemp = [];

    selectedFiles.forEach((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      if (allowedExtensions.includes(fileExtension) && file.size <= maxSize) {
        validFiles.push(file);
      } else {
        invalidFilesTemp.push({
          file,
          error: file.size > maxSize
            ? `File size exceeds ${maxSize / 1024 / 1024} MB`
            : `Invalid file type: ${fileExtension}`,
        });
      }
    });

    setFiles([...files, ...validFiles]);
    setInvalidFiles([...invalidFiles, ...invalidFilesTemp]);
  };

  const handleFileUpload = async (file, clientId) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('clientId', clientId);

    try {
      setProgress(0);
      setUploading(true);

      const Filesdetails = await FilesService.UploadFiles(formData);

      setFileDetails((prevDetails) => [
        ...prevDetails,
        {
          originalFileName: Filesdetails.originalFileName,
          uniqueFileName: Filesdetails.uniqueFileName,
        }
      ]);

      setUploadIndex((prevIndex) => prevIndex + 1);
      setUploading(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setfileuploadingError(error.response.data.errors[0].msg);
      setUploading(false);
    }
  };

  useEffect(() => {
    if (uploadIndex < files.length && !uploading && !fileuploadingError) {
      handleFileUpload(files[uploadIndex], selectedClients);
    }
  }, [uploadIndex, files, selectedClients, uploading, fileuploadingError]);

  const handleSelectChangeCompose = (event) => {
    setSelectedClients(event.target.value);
  };



  const saveFile = async () => {
    const error = validateFileUpload(selectedClients, files);

    if (Object.keys(error).length !== 0) {
      setErrorMessage(error);
      return;
    }

    const data = {
      clientId: selectedClients,
      files: fileDetails,
      folder_id:currentFolderId
    };

    

    try {
      dispatch(setLoading(true));
      dispatch(setMessage(messages.uploading));

      await FilesService.savefile(data);
      uploadFilehandleClose();
      if(currentFolderId){

        getFiles(currentFolderId,selectedClients);
      }else{
        getFiles()
      }
  
      dispatch(setMessage(messages.success.SAVE_FILE));
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };



// const removeFile = (index, isInvalid = false) => {
  //   if (isInvalid) {
  //     const updatedInvalidFiles = invalidFiles.filter((_, i) => i !== index);
  //     setInvalidFiles(updatedInvalidFiles);
  //   } else {
  //     const updatedFiles = files.filter((_, i) => i !== index);
  //     setFiles(updatedFiles);
  //     setUploadIndex(0);
  //   }
  // };
  



  const removeFile = (index, isInvalid = false) => {
    if (isInvalid) {
      const updatedInvalidFiles = invalidFiles.filter((_, i) => i !== index);
      setInvalidFiles(updatedInvalidFiles);
    } else {
      const updatedFiles = files.filter((_, i) => i !== index);
      setFiles(updatedFiles);
  
      
      const updatedFileDetails = fileDetails.filter((_, i) => i !== index);
      setFileDetails(updatedFileDetails);
  
      setUploadIndex(0); 
    }
  };

  useEffect(() => {
    // Only set it if activeClient is non-empty
    if (activeClient) {
      setSelectedClients(activeClient.id);
    }
  }, [activeClient]);


  return ( 
    <div>
    {/* <div className='company-filter mb-3'>
      <select className="form-select form-select-md" aria-label="Default select example" onChange={handleSelectChangeCompose}>
        <option selected disabled>By Corporation</option>
      
        {clientDetails.map((client) => (
          <option key={client.id} value={client.id}>{client.name}</option>
        ))}
      </select>
      {errorMessage.client && <Error error={errorMessage.client} />}
    </div> */}
    <div className='company-filter mb-3'>
  {activeClient ? (
    // If we have an activeClient, show a disabled dropdown
    <select
      className="form-select form-select-md"
      value={selectedClients}        // or activeClient
      disabled
    >
      <option value={activeClient.id}>
        {
          clientDetails.find((c) => c.id === Number(activeClient.id))?.name 
          || 'Selected Client'
        }
      </option>
    </select>
  ) : (
    // Otherwise, show the normal dropdown
    <select
      className="form-select form-select-md"
      aria-label="Default select example"
      onChange={handleSelectChangeCompose}
      value={selectedClients}
    >
      <option disabled value="">
        By Corporation
      </option>
      {clientDetails.map((client) => (
        <option key={client.id} value={client.id}>
          {client.name}
        </option>
      ))}
    </select>
  )}
  {errorMessage.client && <Error error={errorMessage.client} />}
</div>

    <div className='d-flex flex-column mb-3'>
      <input
        type="file"
        id="attachFiles"
        name="Browse Files"
        className="form-control file-upload-field "
        multiple
        onClick={(event) => {
          if (!selectedClients) {
            event.preventDefault(); // Prevent the file dialog from opening
            alert("Please select a client first.");
          }
        }}
        onChange={handleFileSelect}
        // disabled={!selectedClients}
      />
      {errorMessage.files && <Error error={errorMessage.files} />}
      <label htmlFor="attachFiles" className="form-label pt-1">
        Supported formats: PDF, JPG, PNG, XLSX, DOCX, PPTX. Max file size: 5MB.
      </label>
    </div>

    {/* Invalid Files Section */}
    {invalidFiles.map(({ file, error }, index) => (
      <div key={`invalid-${index}`} className="file-upload invalid-file">
        <div className="file-info d-flex justify-content-between align-items-center">
          <div className="file-details">
            <div className="file-name">{file.name}</div>
            <div className="file-error">{error}</div>
          </div>
          <div className="remove-btn">
            <button
              className="btn btn-outline-secondary remove-btn btn-sm"
              onClick={() => removeFile(index, true)}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    ))}

    {/* Valid Files Section */}
    {files.map((file, index) => (
      <div key={index} className="file-upload">
        <div className="file-info d-flex justify-content-between align-items-center">
          <div className="file-details">
            <div className="file-icon">
              <img src={getFileIcon(file.name)} alt={`${file.name} Icon`} className="file-icon" />
            </div>
            <div className="file-name">{file.name}</div>
            <div className="file-size">
              {file.size < 500 * 1024
                ? `${(file.size / 1024).toFixed(2)} KB`
                : `${(file.size / 1024 / 1024).toFixed(0)} MB`}
            </div>
          </div>

          <div className="remove-btn">
            <button
              className="btn btn-outline-secondary remove-btn btn-sm"
              onClick={() => removeFile(index)}
            >
              Remove
            </button>
          </div>
        </div>

        {/* Overlay for upload/pending status */}
        {(uploadIndex === index || uploadIndex < index) && (
          <div className={`file-status-overlay ${uploadIndex === index ? 'uploading' : 'pending'}`}>
            <div className="status-message">
              {uploadIndex === index ? 'Uploading...' : 'Pending...'}
            </div>
          </div>
        )}
      </div>
    ))}

    {/* Action Buttons */}
    <div className="action-buttons d-flex justify-content-between">
      <Button variant="outline-primary" onClick={uploadFilehandleClose}>Cancel</Button>
      <Button variant="primary" onClick={saveFile} disabled={uploadIndex < files.length}>
        {isLoading ? loadingmessage : "Save files"}
      </Button>
    </div>
  </div>
  );
};

export default Document_file_Upload;




















