import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/client-portal/v1/`;
const getLogins = async (id ) => {

    const res = await axios.get(API_URL + "logins/"+id)

    return res.data;

}
const  LoginService={
    
    getLogins,
 

}

export default LoginService