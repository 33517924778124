import React, { useState, useEffect, useRef } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Button } from 'react-bootstrap';
import chatService from '../../services/chat.service';
import { expandIcon } from '../../utils/icons'
import { formatTime } from '../../utils/dateFormat';

import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../feature/message.slice';
import { setLoading } from '../../feature/loading.slice';
import messages from '../../utils/meesage';
import FilesService from '../../services/files.service';
import ChatFiles from './ChatFiles';
import { validateFiles, validateMessage } from '../../utils/validations';
import Error from '../../components/common/Error';
import PreviewFileModal from '../../components/common/Filemanager_PreviewFileModal';
import { downloadFile } from '../../utils/downloadFiles';

const ThreadDetails = ({ threadId,UserId, fetchThreads, subject, hasTasks, setShowTasks, showTasks, message,fetchThreadMessage,client_id }) => {

  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.loading.loading);
  const loadingmessage= useSelector((state) => state.message.message);
  const [files, setFiles] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [fileuploadingError, setfileuploadingError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadIndex, setUploadIndex] = useState(0);
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const chatContainerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [fileuploadError,setfileuploadError]=useState("")
  console.log(fileuploadError)

  const handleMessageChange = async (content) => {
    const cleanedContent = content.replace(/<p><br><\/p>/g, '');
    setNewMessage(cleanedContent);
  };

  const handleClose = () => setShow(false);

  const handleFileSelect = (event) => {
    const selectedFiles = event.target.files;
    
    // Validate the selected files
    const validationErrors = validateFiles(selectedFiles);
  
    if (validationErrors.length > 0) {
      validationErrors.forEach((error) => {
        console.error(`Error with file "${error.fileName}": ${error.reason}`);
      });
  
      
      setfileuploadError(`Error: ${validationErrors.map(error => `${error.fileName}: ${error.reason}`).join(', ')}`)
      
      
    }
  
    // If no validation errors, proceed with adding the files to the state
    setFiles([...files, ...selectedFiles]);
  };

  let fileDetailsArray = [];

  const saveFile=async(fileDetails)=>{

 
    
    const data={
      clientId: client_id,
      files: fileDetails,
    }
    
            try{
          
              var response = await FilesService.savefile(data);
              // uploadFilehandleClose()
           
            }catch(err){
          console.log(err)
            }
  
  
            return response
          }
  
  
    const handleFileUpload = async (file, clientId) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('clientId', clientId);
  
  try {
     
        setUploading(true);
  
   const Filesdetails= await FilesService.UploadFiles(formData);
           
  
  
  fileDetailsArray.push({
    originalFileName: Filesdetails.originalFileName,
    uniqueFileName: Filesdetails.uniqueFileName,
    visibility: 1
  });
  
  
  
        setUploadIndex((prevIndex) => prevIndex + 1); 
        setUploading(false);
      } catch (error) {
        console.error('Error uploading file:', error);
        setfileuploadingError(error.response.data.errors[0].msg)
        setUploading(false);
      }
    };
  



  const sendMessage = async () => {
    try {


      if (files.length > 0 && !newMessage.trim()) {
        setfileuploadError("Message is required when sending files.");
        return;
      }
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));

      if (uploadIndex < files.length && !uploading && !fileuploadingError) {
        for (let i = 0; i < files.length; i++) {
          await handleFileUpload(files[i], client_id);
        }
      }

      let fileIds = [];

      if (fileDetailsArray.length > 0) {
        var saveFiles = await saveFile(fileDetailsArray)

        fileIds = saveFiles.dbEntries.map((entry) => entry.id);

      }

    const data = {
      thread_id: threadId,
      messageBody: newMessage,
      files: fileIds.length > 0 ? fileIds : null
    };


 

      await chatService.SendNewMessage(threadId, data);
      fetchThreadMessage(threadId)
      fetchThreads()
      setNewMessage("");
      setfileuploadError("")

    } catch (err) {
      console.log(err);
    }finally {
      dispatch(setLoading(false));

    }
  };



  const [fileBlob, setFileBlob] = useState(null);
  const [fileType, setFileType] = useState(null);
  const downloadExtensions = ['docx', 'xlsx', 'pptx'];


  const handleShow=async(file)=>{
    try{
    const res= await FilesService.filePreview(file.id)
      
        if (res) {
        
        const blob = res.data;
        const blobUrl=URL.createObjectURL(blob)
        setFileBlob(blobUrl); 
        setFileType(res.headers['content-type'])
        if (downloadExtensions.includes(file.file_type) &&blobUrl ){
         downloadFile(blobUrl, file.file_name);
         }else{
        setShow(true);
      }
      } else {
        console.error('Failed to fetch file');
      }
    }catch(err){
      setFileBlob(null)
      setShow(true);
      console.log(err)
    
    }
    
    
    
    
    }





  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [message]);



  return (
    <div className="thread">
      <div className='container-fluid'>
        <div className="thread__header">
          <span className='thread__subject'>{subject}</span>
          {hasTasks && (<a onClick={() => setShowTasks(!showTasks)} className={`toggle-tasks ${showTasks ? 'tasks-expanded' : 'tasks-collapsed'} `}><img src={expandIcon} width={24} /></a>)}
        </div>
        <div className="thread__chats" >
          <div className='thread__chats__chats' ref={chatContainerRef} >
            {message && message.map((msg) => (
              <div
                key={msg.id}
               
                className={`message ${msg.senderType === "clientportal_user" ? 'by-user' : 'by-other'}`}
              >
                <div className="message__header">
                  <span className="message__sender">{msg.senderType == "clientportal_user" ? 'You' : msg.senderName}</span>
                  <span className="message__time">{formatTime(msg.created_at)}</span>
                </div>
                <div className="message__message" dangerouslySetInnerHTML={{ __html: msg.body }} />

                {msg.files && msg.files.length > 0 && (
                    <ChatFiles files={msg.files} handleShow={handleShow}  />
                  )}
 
              </div>
            ))}
          </div>
        </div>
        <div className="thread__newmessage">

          <ReactQuill
            value={newMessage}
            onChange={handleMessageChange}
            placeholder="Type Message here..."
            theme="snow"
            modules={{
              toolbar: [
                [{ size: [] }],
                ['bold', 'italic', 'underline'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
              ],
            }}

          />
          {/* <Button variant="outline-primary" className="btn btn-sm" onClick={sendMessage}>
            Send
          </Button> */}

          <div className='d-flex w-100 justify-content-between'>

          <div className='input-group input-group-sm' style={{maxWidth: "50%"}}>
            <input type="file" id="attachFiles"  className="form-control form-control-primary" multiple onChange={handleFileSelect} />
            {fileuploadError && <Error error={fileuploadError}/>}
          </div>
          <div>
          <Button variant="outline-primary" className="btn btn-sm" onClick={sendMessage} disabled={isLoading} >
          {isLoading?(loadingmessage):("Send")}
          </Button>
          </div>
          </div>
        </div>
      </div>

      <PreviewFileModal show={show} handleClose={handleClose} fileUrl={fileBlob} 
             fileType={fileType} />
    </div>
  );
};

export default ThreadDetails;
