import React from 'react';
import { Alert } from 'react-bootstrap';

const Error = ({ error }) => {
  // Only render the alert if there's an error message
  if (!error) return null;

  return (
    // <Alert variant="danger" className="w-100 text-center">
    //   {error}
    // </Alert>

    <div style={{fontSize:"0.80rem"}} className="text-danger mt-1">{error}</div>
  );
};

export default Error;
