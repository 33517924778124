import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/client-portal/v1/`;
const getDba = async (id ) => {

    const res = await axios.get(API_URL + "dba/"+id)

    return res.data;

}
const DbaService={
    
    getDba

}

export default DbaService