import { createSlice } from '@reduxjs/toolkit';

const initialState = {
message:"",
 
};

const messsageSlice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    setMessage: (state, action) => {
      state.message = action.payload;
    },
 

  },
});

export const { setMessage} = messsageSlice.actions;

export default messsageSlice.reducer;