import React, { useEffect, useState, useRef } from "react";
import Default from "../../components/layouts/default";
import { Modal } from "react-bootstrap";
import Documents_files from "./Documents_files";
import Documents_trashed from "./Documents_trashed";
import Document_file_Upload from "./Document_file_Upload";
import FilesService from "../../services/files.service";
import View_Approved from "./View_Approved";
import { useDispatch, useSelector } from "react-redux";
import BreadcrumbNavigation from "../../components/common/BreadcrumbNavigation";

import {
  appendToFolderPath,
  removeFromFolderPath,
  resetActiveClient,
  resetFolderPath,
  setActiveClient,
  setFolderPath,
} from "../../feature/folderPath.slice";
import { useLocation, useNavigate } from "react-router-dom";
import { setClient } from "../../feature/client.slice";
import { setLoading } from "../../feature/loading.slice";
import { setMessage } from "../../feature/message.slice";
import messages from "../../utils/meesage";

const Documents = () => {
  // UI state
  const [showUploadFile, setUploadFile] = useState(false);
  const [showViewFile, setViewFile] = useState(false);
  const [files, setFiles] = useState([]);
  const [rootFolder, setRootFolder] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");
  const [selectedFileUrl, setSelectedFileUrl] = useState(null);
  const [dataLoad, setDataLoad] = useState(true);
  const [showTrashed, setShowTrashed] = useState(false);
  const [sortBy, setSortBy] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [trashedFile, setTrashedFile] = useState([]);
  const [clientId, setClientId] = useState(null);



  // Removed pagination state: currentPage, currentSet, TotalPage

  const currentFolderId = useSelector((state) => state.folder.currentFolderId);
  const folderPath = useSelector((state) => state.folder.folderPath);
  const clientDetails = useSelector((state) => state.client.client);

  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
 const activeClient = useSelector((state) => state.folder.activeClient
);


  const uploadFilehandleClose = () => setUploadFile(false);
  const uploadFilehandleShow = () => setUploadFile(true);
  const uploadViewhandleClose = () => setViewFile(false);

  // -------------------- HANDLERS / HELPERS --------------------

  // For searching files/folders by keyword
  const handleChangeSearch = async (e) => {
    const keyword = e.target.value;
    setSearchKeyword(keyword);

    try {
      if (keyword.trim() === "") {
        getFiles(currentFolderId, null);
        return;
      }

      const Searchresponse = await FilesService.FilterFileFolder({
        searchKeyword: keyword,
        folderId: currentFolderId,
      });

      if (Searchresponse) {
        setFiles(Searchresponse);
        setDataLoad(false);
        const resolveFolderPath = (path) =>
          path && path.length > 1 ? path.slice(0, -1) : path;

        const firstFilePath = Searchresponse.files.data?.[0]?.path || [];
        const firstFolderPath =
          Searchresponse.subfolders.data?.[0]?.path?.length > 0
            ? resolveFolderPath(Searchresponse.subfolders.data[0].path)
            : firstFilePath;

        if (firstFilePath.length > 0 || firstFolderPath.length > 0) {
          dispatch(
            setFolderPath(
              firstFilePath.length > 0 ? firstFilePath : firstFolderPath
            )
          );
        } else {
          dispatch(
            setFolderPath(
              currentFolderId ? folderPath : [{ id: 0, name: "Home" }]
            )
          );
        }
      }
    } catch (error) {
      console.error("Error during search:", error);
      dispatch(setFolderPath([{ id: 0, name: "Home" }]));
    }
  };

  // Fetch normal (non-trashed) files/folders
  // const getFiles = async (folderId = null, clientId = null) => {
  //   setDataLoad(true);
  //   dispatch(setMessage(messages.loading));
  //   dispatch(setLoading(true));
  //   try {
  //     // Removed pagination parameters from the service call
  //     const filesResp = await FilesService.getFile({
  //       search: "",
  //       folderId,
  //       clientId,
  //       sortBy: "created_at",
  //       sortDirection: "desc",
  //     });

  //     if (filesResp) {
  //       setFiles(filesResp);
  //       dispatch(setFolderPath(filesResp.folderPath));
  //       if (filesResp.clientDetails && filesResp.clientDetails.length > 0) {
  //         dispatch(setClient(filesResp.clientDetails));
  //       }
  //       if (folderId) {
  //         navigate(`?folderId=${folderId}`);
  //       } else {
  //         navigate(location.pathname);
  //       }
  //     }
  //     setDataLoad(false);
  //     dispatch(setLoading(false));
  //   } catch (err) {
  //     console.log(err);
  //     dispatch(setLoading(false));
  //     const errorMessage =
  //       (err.response && err.response.data && err.response.data.error) ||
  //       err.message ||
  //       "Error fetching files";
  //     dispatch(setMessage(errorMessage));
  //   }
  // };


  const getFiles = async (folderId = null, clientId = null) => {
    setDataLoad(true);
    dispatch(setMessage(messages.loading));
    dispatch(setLoading(true));
  
    try {
      const filesResp = await FilesService.getFile({
        search: "",
        folderId,
        clientId,
        sortBy: "created_at",
        sortDirection: "desc",
      });
  
      if (filesResp) {
        // 1) Normalize folderPath so it doesn't contain a single {id:0/null, name:"Home"}
        let path = filesResp.folderPath || [];
  
        if (
          path.length === 1 &&
          (path[0].id === null || path[0].id === 0) &&
          path[0].name === "Home"
        ) {
          path = [];
        }
  
        // 2) Update Redux
        dispatch(setFolderPath(path));
  
        // 3) Update local state
        setFiles(filesResp);
  
        // 4) If we have client details, store them
        if (filesResp.clientDetails && filesResp.clientDetails.length > 0) {
          dispatch(setClient(filesResp.clientDetails));
        }
  
        // 5) Update URL if folderId is present
        if (folderId) {
          navigate(`?folderId=${folderId}`);
        } else {
          navigate(location.pathname);
        }
      }
  
      setDataLoad(false);
      dispatch(setLoading(false));
    } catch (err) {
      console.log(err);
      dispatch(setLoading(false));
      const errorMessage =
        (err.response && err.response.data && err.response.data.error) ||
        err.message ||
        "Error fetching files";
      dispatch(setMessage(errorMessage));
    }
  };
  
  


  // Navigate into a folder
  const folderDetails = async (id, name,company_id,company_name) => {
    console.log("folderClientId",company_id)
    dispatch(appendToFolderPath({ id, name }));
    if (company_id) {
      dispatch(setActiveClient({ id: company_id, name: company_name }));
    }
    getFiles(id);
  };

  // Fetch trashed files
  const getTrashedFile = async () => {
    const data = {
      search: searchKeyword,
      sortBy: sortBy,
      page: 1,
      sortDirection: sortDirection,
    };
    try {
      const trashedFileResp = await FilesService.getTrashaedFile(data);
      setTrashedFile(trashedFileResp.files);
    } catch (err) {
      setTrashedFile([]);
      console.log(err);
    }
  };

  // Breadcrumb handlers


  // const handleBreadcrumbClick = (index) => {
  //   dispatch(removeFromFolderPath(index));
  //   const targetFolder = folderPath[index];
  //   console.log("targetFolder",targetFolder.id)
  //   if (targetFolder && targetFolder.id !== 0) {
  //     getFiles(targetFolder.id);
  //   } else {
  //     getFiles();
  //     dispatch(resetActiveClient());
  //   }
  // };


  // const handleBreadcrumbClick = (index) => {
  //   // If "Home" is clicked (index 0), reset everything and fetch global root files.
  //   if (index === 0) {
  //     dispatch(resetFolderPath());
  //     dispatch(resetActiveClient());
  //     getFiles();
  //     return;
  //   }
    
  //   // If the client name is clicked (index 1) and an active client is set,
  //   // then reset folderPath (or remove the client crumb) and filter the root by client.
  //   if (index === 1 && activeClient) {
  //     dispatch(resetFolderPath());
  //     getFiles(null, activeClient.id);
  //     return;
  //   }
  
  //   // Otherwise, for deeper crumbs, adjust the index by subtracting 1 since "Home" is prepended.
  //   const actualIndex = index - 1;
  //   const targetFolder = folderPath[actualIndex];
  
  //   if (targetFolder && targetFolder.id !== 0) {
  //     dispatch(removeFromFolderPath(actualIndex));
  //     getFiles(targetFolder.id);
  //   } else {
  //     // Fallback: reset to global root.
  //     dispatch(resetFolderPath());
  //     dispatch(resetActiveClient());
  //     getFiles();
  //   }
  // };
  
  


  const handleBreadcrumbClick = (index, skipIndex) => {
    // index=0 => "Home"
    if (index === 0) {
      dispatch(resetFolderPath());
      dispatch(resetActiveClient());
      getFiles(null, null); // Global root
      return;
    }
  
    // index=1 => "ClientName"
    if (index === 1 && activeClient) {
      dispatch(resetFolderPath());
      getFiles(null, activeClient.id); // client root
      return;
    }
  
    // Otherwise => subfolder
    // folderIndex in folderPath = (index - 2) + skipIndex
    // because crumb 0=Home, crumb 1=ClientName, then subfolders start at crumb 2
    const folderIndex = (index - 2) + skipIndex;
  
    // If folderIndex is invalid, fallback
    if (folderIndex < 0 || folderIndex >= folderPath.length) {
      dispatch(resetFolderPath());
      dispatch(resetActiveClient());
      getFiles(null, null);
      return;
    }
  
    const targetFolder = folderPath[folderIndex];
    dispatch(removeFromFolderPath(folderIndex));
    getFiles(targetFolder.id, activeClient ? activeClient.id : null);
  };
  
  
  
    const handleResetPath = () => {
    dispatch(resetFolderPath());
    dispatch(resetActiveClient());
    getFiles();
  };

  // Handle client/corporation dropdown

  const handleSelectChange = (event) => {
    const clientid = event.target.value;
    if (clientid === "all") {
      if (currentFolderId) {
        getFiles(currentFolderId, null);
      } else {
        getFiles();
      }
    } else if (currentFolderId) {
      getFiles(currentFolderId, clientid);
    } else {
      getFiles(null, clientid);
    }
  };

  // -------------------- useRef to Track Previous Values --------------------
 
 
  const previousRef = useRef({
    folderId: null,
    showTrashed: false,
  });
  // Ref to track first mount
  const firstMountRef = useRef(true);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const folderIdParam = params.get("folderId") || null;

    const folderIdChanged = previousRef.current.folderId !== folderIdParam;
    const trashedChanged = previousRef.current.showTrashed !== showTrashed;

    // --- FIRST MOUNT: always fetch once ---
    if (firstMountRef.current) {
      firstMountRef.current = false;
      if (showTrashed) {
        getTrashedFile();
      } else {
        getFiles(folderIdParam, null);
      }
    } else {
      // Subsequent renders: fetch only if values change
      if (trashedChanged && showTrashed) {
        getTrashedFile();
      } else if (trashedChanged && !showTrashed) {
        folderIdParam ? getFiles(folderIdParam, null) : getFiles();
      } else if (!showTrashed && folderIdChanged) {
        // getFiles(folderIdParam, null);
      }
    }

    previousRef.current.folderId = folderIdParam;
    previousRef.current.showTrashed = showTrashed;
  }, [location.search, showTrashed]);

  // -------------------- RENDER --------------------
  return (
    <Default pageTitle="Documents">
      <div className="documents card w-100">
        <div className="card-body">

     
          <div className="d-flex flex-lg-row flex-column justify-content-between align-items-center">
            <BreadcrumbNavigation
              folderPath={folderPath}
              handleBreadcrumbClick={handleBreadcrumbClick}
              handleResetPath={handleResetPath}
              activeClient={activeClient}
            />
            <div className="documents__toolbar d-flex justify-content-between mb-3">
              <div className="company-filter">
                <select
                  className="form-select form-select-sm"
                  aria-label="Default select example"
                  onChange={handleSelectChange}
                >
                  <option selected disabled>
                    By Corporation
                  </option>
                  <option value="all">All</option>
                  {clientDetails.map((client) => (
                    <option key={client.id} value={client.id}>
                      {client.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="ms-2">
                <input
                  type="search"
                  placeholder="Search"
                  onChange={handleChangeSearch}
                  style={{ boxShadow: "none" }}
                  className="form-control form-control-sm"
                />
              </div>
              <button
                type="button"
                className="btn btn-sm btn-primary ms-2"
                onClick={uploadFilehandleShow}
              >
                Upload File
              </button>
            </div>
          </div>

          <div className="text-end py-2">
            <a
              href="#"
              className={`${
                showTrashed ? "text-secondary" : "text-danger"
              } trashed-link text-decoration-underline`}
              onClick={(e) => {
                e.preventDefault();
                setShowTrashed(!showTrashed);
                dispatch(resetFolderPath());
              }}
            >
              {showTrashed ? "Back to Files" : "Trashed Files"}
            </a>
          </div>

          <div className="documents__content">
            {/* If no data is found */}
            {!showTrashed &&
              !dataLoad &&
              files?.files?.data?.length === 0 &&
              files?.subfolders?.data?.length === 0 && (
                <div className="text-center text-muted">Data not found</div>
              )}

            {/* Normal files view */}
            {!showTrashed &&
              !dataLoad &&
              (files?.files?.data?.length > 0 ||
                files?.subfolders?.data?.length > 0) && (
                <Documents_files
                  responseData={files}
                  getFiles={getFiles}
                  rootFolders={rootFolder}
                  folderDetails={folderDetails}
                />
              )}

            {/* Trashed files view */}
            {showTrashed && (
              <Documents_trashed
                trashedFile={trashedFile}
                getTrashedFile={getTrashedFile}
                closeTrashedView={() => setShowTrashed(false)}
              />
            )}
          </div>
        </div>
      </div>

      <Modal
        className="modal-lg p-0 m-0"
        show={showUploadFile}
        onHide={uploadFilehandleClose}
        animation={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload File(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body className="m-0">
          <Document_file_Upload
            uploadFilehandleClose={uploadFilehandleClose}
            getFiles={getFiles}
          />
        </Modal.Body>
      </Modal>

      <Modal
        className="modal-lg p-0 m-0"
        show={showViewFile}
        onHide={uploadViewhandleClose}
        animation={true}
      >
        <Modal.Body>
          <View_Approved
            uploadFilehandleClose={uploadViewhandleClose}
            selectedFileUrl={selectedFileUrl}
          />
        </Modal.Body>
      </Modal>
    </Default>
  );
};

export default Documents;
