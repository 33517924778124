import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import chatService from "../services/chat.service";
import { setMessage } from "./message.slice";
import { setLoading } from "./loading.slice";
import messages from "../utils/meesage";



export const fetchAllThreads = createAsyncThunk(
  "Thread/fetchAllThreads",
  async ({data},thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    thunkAPI.dispatch(setMessage(messages.loading))
    try {
      const threads = await chatService.getThread(data) ;
     
      return threads;
    } catch (error) {
  
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message)||
        error.message ||
        error.toString();
  //  console.log(message)
  thunkAPI.dispatch(setMessage(message))
      return thunkAPI.rejectWithValue();
    }finally {
      thunkAPI.dispatch(setLoading(false)); 
    thunkAPI.dispatch(setMessage(""))

    }
  }
);


export const fetchThreadsById = createAsyncThunk(
  "Thread/fetchThreadsByID",
  async ({ThreadsId},thunkAPI) => {
   
    try {
      const thread = await chatService.getThreadsByid(ThreadsId) ;
    //   thunkAPI.dispatch(setMessage(data.message));
      return thread;
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      
  
      return thunkAPI.rejectWithValue();
    }
  }
);





const chatSlice = createSlice({
    name: 'threads',
    initialState:{
threads:[],
thread:[]
    },
    reducers:{},
    extraReducers(builder) {
       
 

        builder.addCase(fetchAllThreads.fulfilled, (state, action) => {
          state.threads =  action.payload;
})


builder.addCase(fetchThreadsById.fulfilled, (state, action) => {
 
  state.thread = action.payload;
})

 }
})


export default chatSlice.reducer