import React from 'react'
import { Modal, Button } from 'react-bootstrap';
const ConfirmationBox = ({ show, handleClose, handleConfirm }) => {
  return (
<Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure? This cannot be undone.</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleConfirm}>
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}

export default ConfirmationBox
