import React from 'react';
import { Row, Col, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import logo from "../../assets/images/adm_logo.png";
import loginbg from "../../assets/images/login_bg.png";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Auth = ({ children, buttonClick, ApiSuccess, ApiError, ApiErrorMessage, pageTitle }) => {
  return (
    <section className="auth-container">

      <Row>
        <Col md="6" style={{position:"relative"}} >

          {buttonClick && ApiError && (
            <div className='alert-box'>
              <Alert variant="danger " role="alert">
                <FontAwesomeIcon icon={faExclamationTriangle} className="me-2" size="lg" />
                <div>{ApiErrorMessage}</div>
              </Alert>
            </div>
          )}
          {buttonClick && ApiSuccess && (
            <div className='alert-box'>
              <Alert variant="success" role="alert">
                <FontAwesomeIcon icon={faCheckCircle} className="me-2" size="lg" />
                <div>{ApiErrorMessage}</div>
              </Alert>
            </div>
          )}

          <div className='auth-form'>
            <div className="auth-card">
              <div className="auth-header">
                <img src={logo} width="280px" alt="Logo" />
                <h2 className="mb-2 text-center">{pageTitle}</h2>
              </div>

              {children}
            </div>
          </div>
        </Col>
        <Col md="6" className='auth-bg'>
        </Col>
      </Row>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </section>
  );
};

export default Auth;
