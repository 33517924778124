import axios from 'axios';
const API_URL = "https://api.ipify.org?format=json";
const getIp = async (id ) => {

    const res = await axios.get(API_URL)

    return res.data;

}
const IpService={
    
    getIp

}

export default IpService