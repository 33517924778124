import React, { useEffect, useRef, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Auth from '../../components/layouts/auth';
import authService from '../../services/forgetPassword.service';
import Cookies from 'js-cookie';
import { validateEmail, validateOtp } from '../../utils/validations';
import { 
  isMobile, isTablet, isBrowser, 
  browserName, browserVersion, 
  osName, osVersion, deviceType 
} from 'react-device-detect';
import IpService from '../../services/Ip.service';
import { setMessage } from '../../feature/message.slice';
import { setLoading } from '../../feature/loading.slice';
import messages from '../../utils/meesage';
import { useDispatch, useSelector } from 'react-redux';
const LoginOtp = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch()
  const isLoading = useSelector((state) => state.loading.loading);
  const message= useSelector((state) => state.message.message);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailFromUrl = queryParams.get('email');
  const emailInputRef = useRef(null);
  const [formData, setFormData] = useState({
    email:emailFromUrl|| '',
    otp: '',
  });

  const [errors, setErrors] = useState({ email: '', otp: '' });

  const [ApiError, setApiError] = useState(false);
  
  const [ApiSuccess, setApiSuccess] = useState(false);

  const [buttonClick, setbuttonClick] = useState(false);
  const [ApiErrorMessage, setApiErrorMessage] = useState("");

  const [userId, setuserId] = useState("")
  const [otpFieldVisible, setOtpFieldVisible] = useState(false);

  const [minutes, setMinutes] = useState(1); // 1 minute timer
  const [seconds, setSeconds] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [resendLink, setresendLink] = useState(false)
  const [ip, setIp] = useState('');
  const [deviceInfo, setDeviceInfo] = useState([]);

  useEffect(() => {
    if (ApiError || ApiErrorMessage !== "") {
      const timer = setTimeout(() => {
        setApiError(false);
        setApiSuccess(false)
        setApiErrorMessage("");
      }, 5000); // Reset after 5 seconds

      
      return () => clearTimeout(timer);
    }
    if(errors.otp!==""){
      const timer = setTimeout(() => {
        setErrors(prevErrors => ({
          ...prevErrors,
          otp:""
        }));
  
      }, 5000); // Reset after 5 seconds

      
      return () => clearTimeout(timer);
    }
  }, [ApiError, ApiErrorMessage,errors]);







  useEffect(() => {
    let interval;
    if (canResend) {
      interval = setInterval(() => {
        clearInterval(interval);
        if (seconds === 0) {
          if (minutes !== 0) {
            setSeconds(59);
            setMinutes(minutes - 1);
          } else {
            setCanResend(false)
            setresendLink(true);
          }
        } else {
          setSeconds(seconds - 1);
        }
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [seconds, minutes, canResend]);


  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [id]: value
    }));
  };

  const handleOTPChange = (e) => {
    const { id, value } = e.target;
    const numericValue = value.replace(/\D/g, ''); 
   setFormData(prevState => ({
        ...prevState,
        [id]: numericValue
      }));


   
  };
  

  const handleSignIn = async (event) => {
    event.preventDefault()
    const formErrors = validateEmail(formData.email); 
         setErrors(prevErrors => ({
        ...prevErrors,
        email: formErrors
      }));

    if (Object.keys(formErrors).length === 0) {
    
      const data = {
        email: formData.email,
        ipAddress:ip,
        deviceInfo:deviceInfo
      }
      try {
        dispatch(setLoading(true));
        dispatch(setMessage(messages.loading));
        const users = await authService.LoginWithOtp(data);
       
        setbuttonClick(true);
        setApiSuccess(true);
        setApiErrorMessage(users.message);

        setOtpFieldVisible(true);
        setuserId(users.userId)
        setMinutes(1);
        setSeconds(0);
        setCanResend(true);

      } catch (err) {
        setbuttonClick(true);
        setApiError(true);
        setApiErrorMessage(err.response.data);
      }finally {
        dispatch(setLoading(false));
  
      }
    }
  };


  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();  
    }
  }, []);





  const setAccessToken = (token) => {
    Cookies.set('token', token, { expires: 1 / 12 });
  };

  const setRefreshToken = (token) => {
    Cookies.set('Refreshtoken', token, { expires: 7 });  
  };


  const setUserDetails = (res) => {
    const id = res.user.id;
    const name = res.user.name;
    const client = res.user.clients;
    const acesssToken = res.token.access.token;
    const refreshToken = res.token.refresh.token;
    const middleName = res.user.surname;
    localStorage.setItem("userName", name);
    localStorage.setItem("middleName", middleName);

    // localStorage.setItem("userId", id);
    // localStorage.setItem("client", client);
    // localStorage.setItem("refreshToken", refreshToken);
    // setAccessToken(acesssToken);
    // setRefreshToken(refreshToken);
  };

  const setToken=(token)=>{
      Cookies.set('admone_cp', JSON.stringify(token), { 
        expires: 1 / 12, 
        // expires: 15 / 1440,
        path: '/',
        // domain: process.env.REACT_APP_COOKIE_URL || undefined,
        // secure: secureFlag,  // Set secure flag
      
      }); 
    }
  
  const handleVerifyOtp = async (event) => {
    event.preventDefault()
    const formErrors = validateOtp(formData.otp); 
    
    setErrors(prevErrors => ({
      ...prevErrors,
      otp: formErrors
    }));

    if (Object.keys(formErrors).length === 0) {
      try {
        dispatch(setLoading(true));
        dispatch(setMessage(messages.loading));
        const data = {
          otp: formData.otp,
          userId: userId,
          ipAddress:ip,
          deviceInfo:deviceInfo
        }
        const users = await authService.VerifyOtp(data);
        const tokens = {
          accessToken: users.token.access.token,
          refreshToken:  users.token.refresh.token
        };
            setToken(tokens)
       
        setUserDetails(users)
        setbuttonClick(true);
        setApiSuccess(true);
        setApiErrorMessage(users.message);
        setTimeout(() => {
             
         navigate(`/dashboard`);
        }, 2000);
        
      } catch (err) {
        setbuttonClick(true);
        setApiError(true);
        setApiErrorMessage(err.response.data);
      }finally {
        dispatch(setLoading(false));
  
      }
    }
  };


  const resendOTP = async (event) => {
    setMinutes(1);
    setSeconds(0);
    setCanResend(true);
    setresendLink(false)
    handleSignIn(event);
    setFormData(prevState => ({
      ...prevState,
      otp: ""
    }));
  };


  const fetchIp = async () => {
    try {
      const response = await IpService.getIp();
      setIp(response.ip);
    
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }


  }

  useEffect(()=>{
    fetchIp()
    const deviceData = [
      { label: 'Browser', value: `${browserName} ${browserVersion}` },
      { label: 'OS', value: `${osName} ${osVersion}` },
      { label: 'Device Type', value: deviceType },
      { label: 'Is Mobile', value: isMobile ? 'Yes' : 'No' },
      { label: 'Is Tablet', value: isTablet ? 'Yes' : 'No' },
      { label: 'Is Browser', value: isBrowser ? 'Yes' : 'No' }
    ];

    setDeviceInfo(deviceData);
  },[])




  const handleKeyDown = (event) => {

    if (event.key == 'Enter' &&otpFieldVisible) {
      handleVerifyOtp(event);
    }else if(event.key == 'Enter'&&!otpFieldVisible){
      handleSignIn(event);

    }
  };

  return (
    <Auth buttonClick={buttonClick} ApiSuccess={ApiSuccess} ApiError={ApiError} ApiErrorMessage={ApiErrorMessage} pageTitle="Log in with OTP">
      <Form   onSubmit={otpFieldVisible ? handleVerifyOtp : handleSignIn} onKeyDown={handleKeyDown}>

        <Form.Group className="form-group">
          <div className="text-center mt-3">
            <Form.Label htmlFor="email">Email</Form.Label>
          </div>
          <Form.Control
            ref={emailInputRef}
            type="email"
            isInvalid={!!errors.email}
            id="email"
            aria-describedby="email"
            placeholder=" "
            value={formData.email}
            onChange={handleChange}
          />
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>



        {otpFieldVisible && (

          <Form.Group className="form-group">
            <div className="text-center mt-3">
              <Form.Label htmlFor="otp">Enter OTP</Form.Label>

            </div>
            <div className='otp-input-container'>
              <Form.Control
                type="text"
                isInvalid={!!errors.otp}
                id="otp"
                aria-describedby="otp"
                placeholder="______"
                value={formData.otp}
                onChange={handleOTPChange}
                className="otp-input"
                 inputMode="numeric"  
                 pattern="[0-9]*"
              />
                 <Form.Control.Feedback type="invalid">
              {errors.otp}
            </Form.Control.Feedback>
            </div>
         
            <div className="countdown-text">
              {canResend &&
                <div className='otp-timer'>
                  You can request new OTP in {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </div>}

              {resendLink && <Link className='otp-resend-link' onClick={resendOTP} >
                Resend OTP
              </Link>}

            </div>
          </Form.Group>

        )}
        <div className="text-center pt-3">
          {/* <Button className="btn-expanded" onClick={handleSignIn} type="button" variant="btn btn-primary">Send OTP</Button> */}
          {/* <Button className="btn-expanded" type="submit" variant="btn btn-primary">
            {otpFieldVisible ? 'Sign in' : 'Send OTP'}
          </Button> */}


          <Button className="btn-expanded" type="submit" variant="btn btn-primary" disabled={isLoading}>
            {otpFieldVisible ? `${isLoading?(message):("Sign in")}` : `${isLoading?(message):("Send OTP")}`}
          </Button>




        </div>
        <div className="text-center pt-3">
          <Link to="/" className="secondary-cta">Back to Login</Link>
        </div>
      </Form>
    </Auth>
  );
};

export default LoginOtp;
