import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Auth from '../../components/layouts/auth';
import authService from '../../services/forgetPassword.service';

const Logiotp = () => {
   let navigate = useNavigate();
   const { id } = useParams();
 

   const [formData, setFormData] = useState({
      otp: '',
      userId: id
   });

   const [errors, setErrors] = useState({ otp: '' });
   const [ApiError, setApiError] = useState(false);
   const [buttonClick, setbuttonClick] = useState(false);
   const [ApiErrorMessage, setApiErrorMessage] = useState("");

   const handleChange = (e) => {
      const { id, value } = e.target;
      setFormData(prevState => ({
         ...prevState,
         [id]: value
      }));

      if (id === 'otp') {
         const isValidOtp = value.length === 6;
         setErrors(prevErrors => ({
            ...prevErrors,
            otp: isValidOtp ? '' : 'OTP must be 6 digits'
         }));
      }
   };

   const handleSignIn = async () => {
      const isValidOtp = formData.otp.length === 6;

      if (!isValidOtp) {
         setErrors(prevErrors => ({
            ...prevErrors,
            otp: 'OTP must be 6 digits'
         }));
      }

      if (isValidOtp) {
         try {
            const users = await authService.VerifyOtp(formData);
            setbuttonClick(true);
            setApiError(false);
            setApiErrorMessage(users.message);
            navigate(`/dashboard`);
         } catch (err) {
            setbuttonClick(true);
            setApiError(true);
            setApiErrorMessage(err.response.data);
         }
      }
   };

   return (
      <Auth buttonClick={buttonClick} ApiError={ApiError} ApiErrorMessage={ApiErrorMessage} pageTitle="Log in with OTP">
         <Form>
            <Form.Group className="form-group">
               <div className="text-center mt-3">
                  <Form.Label htmlFor="otp">OTP</Form.Label>
               </div>
               <Form.Control
                  type="text"
                  isInvalid={!!errors.otp}
                  id="otp"
                  aria-describedby="otp"
                  placeholder=" "
                  value={formData.otp}
                  onChange={handleChange}
               />
               <Form.Control.Feedback type="invalid">
                  {errors.otp}
               </Form.Control.Feedback>
            </Form.Group>
            <div className="d-flex justify-content-center pt-3">
               <Button className='btn btn-md px-4 rounded' onClick={handleSignIn} type="button" variant="btn btn-primary">Sign In</Button>
            </div>
            <div className='text-center pt-3'>
               <Link to="/" className="secondary-cta">Back to Login</Link>
            </div>
         </Form>
      </Auth>
   );
};

export default Logiotp;
