import axiosInstance from '../utils/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/folder/`;



const getAllFolders=async(data)=>{
    const res = await axiosInstance.post(API_URL,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;
}

const FolderService={
    
    getAllFolders

}

export default FolderService