import React, { useState } from 'react';
import { Form, Button, Row, Col, Alert } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Auth from '../../components/layouts/auth';
import { showPasswordIcon, hidePasswordIcon } from '../../utils/icons';
import authService from '../../services/forgetPassword.service';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { resetPassword } from '../../utils/validations';
import { useDispatch, useSelector } from 'react-redux';
import { setMessage } from '../../feature/message.slice';
import { setLoading } from '../../feature/loading.slice';
import messages from '../../utils/meesage';

const ResetPassword = () => {
   let navigate = useNavigate();
   const location = useLocation();
   const isLoading = useSelector((state) => state.loading.loading);
   const message= useSelector((state) => state.message.message);
   const dispatch = useDispatch()
   const queryParams = new URLSearchParams(location.search);
   const token = queryParams.get('aa');

   const [formData, setFormData] = useState({
      password: '',
      confirmPassword: ''
   });

   const [errors, setErrors] = useState({ password: '', confirmPassword: '' });
   // console.log(errors)
   const [ApiError, setApiError] = useState(false);
   const [ApiSuccess, setApiSuccess] = useState(false);
   const [buttonClick, setbuttonClick] = useState(false);
   const [ApiErrorMessage, setApiErrorMessage] = useState("");
   const [showPassword, setShowPassword] = useState(false);
   const [showConfirmPassword, setShowConfirmPassword] = useState(false);

   const handleChange = (e) => {
      const { id, value } = e.target;
      setFormData(prevState => ({
         ...prevState,
         [id]: value
      }));
   };

   const handleResetPassword = async (event) => {
      event.preventDefault()

    
      const formErrors = resetPassword(formData);

  
      if (formErrors) {
         setErrors(formErrors)
      }
      if (Object.keys(formErrors).length === 0) {
         try {
            dispatch(setLoading(true));
            dispatch(setMessage(messages.loading));
            const data = {
               new_password: formData.confirmPassword
            }
            const response = await authService.resetPassword(data, token);
            //  console.log(response)
            setbuttonClick(true);
            setApiSuccess(true)
            setApiErrorMessage(response.message);
            setTimeout(() => {
             
               navigate(`/login?email=${encodeURIComponent(response.data.email)}`);
             }, 2000);
         } catch (err) {
            setbuttonClick(true);
            setApiError(true);
            setApiErrorMessage(err.response.error);
         }finally {
            dispatch(setLoading(false));
      
          }


      }
   };

   const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
   };

   const toggleConfirmPasswordVisibility = () => {
      setShowConfirmPassword(!showConfirmPassword);
   };

   return (
      <Auth buttonClick={buttonClick} ApiSuccess={ApiSuccess} ApiError={ApiError} ApiErrorMessage={ApiErrorMessage} pageTitle="Reset Password">
         <Form onSubmit={handleResetPassword}> {/* Correct the onSubmit handler here */}
            <Form.Group className="form-group mb-3">
               <div className="text-center">
                  <Form.Label htmlFor="password">Password</Form.Label>
               </div>
               <div className="position-relative">
                  <Form.Control
                     type={showPassword ? "text" : "password"}
                     isInvalid={!!errors.password}
                     id="password"
                     aria-describedby="password"
                     placeholder=" "
                     value={formData.password}
                     onChange={handleChange}
                  />
                  {!errors.password && (
                     <span
                        className="position-absolute password-toggle-icon"
                        onClick={togglePasswordVisibility}
                     >
                        <img
                           src={`${showPassword ? hidePasswordIcon : showPasswordIcon}`}
                           width={20}
                           style={{ opacity: "0.3" }}
                        />
                     </span>
                  )}
                  <Form.Control.Feedback type="invalid">
                     {errors.password}
                  </Form.Control.Feedback>
               </div>
            </Form.Group>

            <Form.Group className="form-group mb-3">
               <div className="text-center">
                  <Form.Label htmlFor="confirmPassword">Confirm Password</Form.Label>
               </div>
               <div className="position-relative">
                  <Form.Control
                     type={showConfirmPassword ? "text" : "password"}
                     isInvalid={!!errors.confirmPassword}
                     id="confirmPassword"
                     aria-describedby="confirmPassword"
                     placeholder=" "
                     value={formData.confirmPassword}
                     onChange={handleChange}
                  />
                  {!errors.confirmPassword && (
                     <span
                        className="position-absolute password-toggle-icon"
                        onClick={toggleConfirmPasswordVisibility}
                     >
                        <img
                           src={`${showConfirmPassword ? hidePasswordIcon : showPasswordIcon}`}
                           width={20}
                           style={{ opacity: "0.3" }}
                        />
                     </span>
                  )}
                  <Form.Control.Feedback type="invalid">
                     {errors.confirmPassword}
                  </Form.Control.Feedback>
               </div>
            </Form.Group>

            <div className="text-center">
               {/* <Button className='btn-expanded' type="submit" variant="btn btn-primary">Reset</Button> */}
               <Button className='btn-expanded' type="submit" variant="btn btn-primary" disabled={isLoading} >{isLoading?(message):("Reset")}</Button>

            </div>
            <div className='text-center pt-3'>
               <Link to="/" className="secondary-cta">Back to Login</Link>
            </div>
         </Form>
      </Auth>

   );
};

export default ResetPassword;
