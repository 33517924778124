import Cookies from 'js-cookie';
import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
const getAccessToken = () => {
  return Cookies.get('token');
};
const PublicRoutes = () => {
  const token = getAccessToken();
  const isAuthenticated = !!token
if (isAuthenticated) {
 return <Navigate to="/dashboard" />;
  }
  return <Outlet />;
};

export default PublicRoutes;
