import React, { memo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const SubHeader = memo(({ pageTitle, pageSubTitle }) => {
  const location = useLocation();
  const [userId, setUserId] = useState("");
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    const storedUserName = localStorage.getItem('userName');
    setUserId(storedUserId);
    setUserName(storedUserName);
  }, []);

  const isHome = location.pathname.includes('/dashboard');

  return (
    <div className={`sub-header ${!isHome ? 'sub-header-internal' : ''}`}>
      <div className='container-fluid'>
        <h1 className='mb-1'>{pageTitle}</h1>
        {isHome && <p>{pageSubTitle}</p>}
      </div>
    </div>
  );
});

export default SubHeader;
