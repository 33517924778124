import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

import { formatDateTime } from "../../utils/dateFormat";

const RecentThreads = ({ Threads }) => {
  const navigate = useNavigate();

  const handleRowClick = (thread) => {
    navigate("/chats", { state: { NotificationThreadId: thread.id } });
  };

  const handleAllChats = () => {
    navigate("/chats");
  };

  const truncateMessage = (message, limit) => {
    const div = document.createElement("div");
    div.innerHTML = message;
    const text = div.textContent || div.innerText || "";
    return text.length <= limit ? text : `${text.substring(0, limit)}...`;
  };

  return (
    <div className="dashboard__card">
      <div className="d-flex justify-content-between align-items-center">
        <h5 className="dashboard__card__title">Recent Chats</h5>
        {Threads.length > 0 && (
          <Button
            className="btn btn-sm btn-link all_chats"
            variant="outline-primary"
            onClick={handleAllChats}
          >
            SEE ALL CHATS
          </Button>
        )}
      </div>
      {Threads.length > 0 ? (

        <div className="table-responsive">
  <Table className="table table-sm">
    <thead>
      <tr>
        <th width="25%">Received on</th>
        <th>Message</th>
        <th>Last Message by</th>
      </tr>
    </thead>
    <tbody>
      {Threads.map((thread) => (
        <tr
          className="table-row"
          key={thread.id}
          onClick={() => handleRowClick(thread)}
          style={{ cursor: "pointer" }}
        >
          <td>{thread.lastMessageTime}</td>
          <td width="50%">
            {truncateMessage(thread.lastMessage, 100)}
          </td>
          <td>
            <span className="chats-list__user">
              {thread.lastMessageSender} ({thread.lastMessageSenderCompany})
            </span>
          </td>
        </tr>
      ))}
    </tbody>
  </Table>
</div>

      ) : (
        "No Recent Chats"
      )}
    </div>
  );
};

export default RecentThreads;
