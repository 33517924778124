import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  clientId: [],
  client:[],
};

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setClient: (state, action) => {
      state.client = action.payload;
    }

  },
});

export const { setClientId,setClient} = clientSlice.actions;

export default clientSlice.reducer;