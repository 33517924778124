import React from 'react';
import { useSelector } from 'react-redux';

const Loading = () => {
  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000
  };

 
  const message= useSelector((state) => state.message.message);
  return (
    // <div style={overlayStyle}>
      <div className="loading-message" >
       {message}
      </div>
    // </div>
  );
};

export default Loading;
