import React from 'react'

const Footer = () => {
  return (
    <div className='footer'>
      <div className='container-fluid'>

      <div className='footer-left'>
        <a href="#" className='footer-links'>Privacy Policy</a>
        <a href="#" className='footer-links'>Terms of Use</a>
      </div>

      <div className='footer-right'>
        <span className='copyright'>© 2024. ADM LLC</span>
      </div>
      </div>
    </div>
  )
}

export default Footer