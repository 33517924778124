import React from 'react';

const Success = ({ message }) => {
  // Only render the message if it exists
  if (!message) return null;

  return (
    <div style={{ fontSize: "0.80rem" }} className="text-success mt-1">
      {message}
    </div>
  );
};

export default Success;
