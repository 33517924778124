import React from 'react';
import { Badge, Row, Col, } from "react-bootstrap";
import { InputGroup, FormControl } from 'react-bootstrap';

const BillingCustom = () => {
  return (
    <div className='BillingCustom_wrapper text-start' >
      <div style={{ width: '100%' }} className="py-5 card">
        <div className="mb-3 px-4" >
          <Row className="px-3 gap-3">
            <Col className="mb-3 rounded text-start " xs={12} md={2} style={{ background: "#F3C2BC" }}>
              <div className=" w-100 p-3" >
                <p className="fs-6 text-danger fw-bold m-0" >Oustanding</p>
                <span className="fs-1 text-black fw-semibold">$120</span>
              </div>
            </Col>
            <Col className="mb-3 rounded border border-dark text-start " xs={12} md={3}>
              <div className=" w-100 p-3 ">
                <p className="fs-6 text-danger fw-bold m-0 pb-4 ">Pay Invoice #1881 - $120</p>
                <button type="button" style={{ width: "120px", fontSize: "12px", height: "35px" }} class="btn btn-primary btn-sm">Make Payment</button>
              </div>
            </Col>
          </Row>
        </div>
        <div className=" mb-3" >
          <div className=" p-0">
            <div className="d-flex justify-content-between  flex-column-reverse flex-md-row py-3 px-4 " >
              <div className="input-group p-2 border rounded border-0 justify-content-between custom-tabs " style={{ width: "250px", backgroundColor: "#F2F2F2" }}>
                <label className="input-group-text border-0 bg-transparent text-black customlabel " htmlFor="inputGroupFile01">Invoices</label>
                <label className="input-group-text border-0 bg-transparent text-black customlabel " htmlFor="inputGroupFile01">Payments</label>
              </div>
              <div className="d-flex align-items-center mb-2">
                <div className=" pe-0 pe-lg-1 d-flex align-items-center">
                <InputGroup className=" pe-1 shadow-none ">
                        <FormControl
                            type="search"
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="button-addon2"
                            style={{ boxShadow: 'none', borderColor: '' }}
                        />
                    </InputGroup>
                </div>
              </div>
            </div>
            <div className='table-responsive' >
              <table className="table mt-4 " style={{ width: "100%" }} >
                <thead>
                  <tr>
                    <th scope="col" className='p-3'>Invoice#</th>
                    <th scope="col" className='p-3' >Status</th>
                    <th scope="col" className='p-3' >Amount</th>
                    <th scope="col" className='p-3' >Description</th>
                    <th scope="col" className='p-3' >Uploaded On</th>
                    <th scope="col" className='p-3' >Paid On</th>
                  </tr>
                </thead>
                <tbody className="">
                  <tr>
                    <th scope="row" className="text-black p-3">#1881</th>
                    <td><Badge pill bg="danger" className='fw-semibold  '>Danger</Badge></td>
                    <td className="text-black fw-bold p-3 " >$120</td>
                    <td className="text-black fw-medium p-3 "  >Tax Returns for year</td>
                    <td className="text-black p-3 " >Jan 17, 2024</td>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-black p-3">#8987</th>
                    <td><Badge pill bg="success" className='fw-semibold' >Success</Badge></td>
                    <td className="text-black fw-bold p-3 " >$421</td>
                    <td className="text-black fw-medium p-3 " >Bookkeeping</td>
                    <td className="text-black p-3 " >Jan 17, 2024</td>
                    <td className="text-black p-3 ">Jan 17, 2024</td>
                  </tr>
                  <tr>
                    <th scope="row" className="text-black p-3 ">#2271</th>
                    <td><Badge pill bg="secondary" className='fw-semibold' >Cancelled</Badge></td>
                    <td className="text-black fw-bold p-3 " >$99</td>
                    <td className="text-black fw-medium p-3 ">Sales Tax Returns</td>
                    <td className="text-black p-3 ">Jan 17, 2024 </td>
                    <td className="text-black p-3 ">Jan 17, 2024</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillingCustom