
import axios from 'axios';
import Cookies from 'js-cookie';
const axiosInstance = axios.create({
    
    // headers: {
    //     'Content-Type': 'application/json'
    // }
});


// Add an interceptor for all requests
axiosInstance.interceptors.request.use(config => {
 

    let userToken =  Cookies.get('token');

    const accessToken = userToken;


    // Add the access token to the Authorization header
    config.headers.Authorization = `Bearer ${accessToken}`;

    return config;
});

export default axiosInstance;