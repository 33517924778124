import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/client-portal/v1/`;
const geShareHolder = async (id ) => {

    const res = await axios.get(API_URL + "shareholder/"+id)

    return res.data;

}
const  ShareholdersService={
    
    geShareHolder,
 

}

export default ShareholdersService