import React, { useEffect } from 'react'
import { useState, useRefuseRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import ContactService from '../../../services/contact.service';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons'; // Import contacts icon

const CompanyDataContact = ({ contacts }) => {

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    streetName: Yup.string().required('Street Name & Number is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.number().required('Zip is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.number().required('State is required'),
    Phone: Yup.number().required('State is required')
  });
  const [showAddpopup, setAddpopup] = useState(false);
  const [showViewPopup, setViewPopup] = useState(false);
  const [ContactDetails, setContactDetails] = useState([])
  const [ContactAllDetails, setContactAllDetails] = useState([])
  const handleClose = () => setAddpopup(false);
  const handleShow = () => setAddpopup(true);
  const viewHandleClose = () => setViewPopup(false);
  const viewHandleShow = (ContactDetails) => {

    setContactAllDetails(ContactDetails)


    setViewPopup(true)
  };


  // const clientId = useSelector((state) => state.client.clientId);
  // const fetchContactDetails=async()=>{

  //   try{

  //     const ContactDetails=await ContactService.getContact(clientId)
  //     setContactDetails(ContactDetails)
  //     console.log(ContactDetails)
  //   }catch(err){
  //   console.log(err)
  //   }

  //   }


  //   useEffect(()=>{
  //     fetchContactDetails()
  //   },[clientId])

  return (
    <>
      {contacts != 0 &&
       <Card className="moreinfo-card">
       <div>
         <div className="d-flex justify-content-between py-1">
           <h5 className="fw-bold card-title">
             <FontAwesomeIcon icon={faAddressBook} className="card-icon" /> Contacts
           </h5>
         </div>
         <div className="card-divider"></div>
         
         {contacts && contacts.map((ContactDetails, index) => (
           <div
             className={`d-flex flex-wrap py-3 card-details ${contacts.length === 1 || index === contacts.length - 1 ? 'no-border' : ''}`}
             key={ContactDetails.full_name}
           >
             <div className="card-info half-width mb-2">
               <h6 className="mb-1">Full Name:</h6>
               <p className="text-black mb-0">{ContactDetails.full_name || '-'}</p> {/* Show '-' if empty */}
             </div>
             <div className="card-info half-width mb-2">
               <h6 className="mb-1">Designation:</h6>
               <p className="text-black mb-0">{ContactDetails.designation || '-'}</p> {/* Show '-' if empty */}
             </div>
             <div className="card-info half-width mb-2">
               <h6 className="mb-1">Email:</h6>
               <p className="text-black mb-0">{ContactDetails.email || '-'}</p> {/* Show '-' if empty */}
             </div>
             <div className="card-info half-width mb-2">
               <h6 className="mb-1">Phone:</h6>
               <p className="text-black mb-0">{ContactDetails.phone || '-'}</p> {/* Show '-' if empty */}
             </div>
           </div>
         ))}
       </div>
     </Card>
     
      }

      {/* <Modal show={showViewPopup} onClick={viewHandleClose} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>View Contact Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" py-2 px-3">
            <div className="row justify-content-start ">


              <div className="col col-lg-4">
                <span className='text-black' style={{ fontSize: '15px' }}>First Name</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{ContactAllDetails.name}</p>
              </div>
              {ContactAllDetails.middlename && <div className="col col-lg-4">
                <span className='text-black' style={{ fontSize: '15px' }}>Middle Name</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{ContactAllDetails.middlename}</p>
              </div>}
              {ContactAllDetails.surname && <div className="col col-lg-4">
                <span className='text-black' style={{ fontSize: '15px' }}>Last Name</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{ContactAllDetails.surname}</p>
              </div>}




            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Email</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ContactAllDetails.email}</p>
              </div>
              {ContactAllDetails.designation && <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Designation</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ContactAllDetails.designation}</p>
              </div>}
            </div>
            <div className="d-flex justify-content-start mb-4">
              {ContactAllDetails.phone && <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Phone</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ContactAllDetails.phone
                }</p>
              </div>}
              {ContactAllDetails.dba != null && <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>DBA</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{ContactAllDetails.dba && ContactAllDetails.dba.name
                }</p>
              </div>}
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

    </>




  )
}

export default CompanyDataContact