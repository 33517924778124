import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder } from '@fortawesome/free-solid-svg-icons';
import { Table } from 'react-bootstrap';
import { formatDateTime } from '../../utils/dateFormat';
import Pagination from '../../components/common/Pagination';
const Documents_folder = ({ folders, setActivate, getFolderDetails, currentPage, TotalPage, currentSet, setCurrentSet, setcurrentPage,setSortDirection,setSortBy,sortBy,sortDirection }) => {

  const openFolderDetails = (id, name) => {
    setActivate(4)
    getFolderDetails(id, name)
  }


  const handleSort = (column) => {
    const newDirection = sortBy === column && sortDirection === "ASC" ? "DESC" : "ASC";
    setSortBy(column);
    setSortDirection(newDirection);
    
  };



  return (
    <div className='pt-2'>

      <Table hover responsive   >
        <thead>
          <th width="60%"  onClick={() => handleSort("name")}>Folder Name {sortBy === "name" && (sortDirection === "ASC" ? "↑" : "↓")}</th>
          <th width="20%">No of files</th>
          <th width="20%"   onClick={() => handleSort("createdAt")}>Created On  {sortBy === "createdAt" && (sortDirection === "ASC" ? "↑" : "↓")}</th>


        </thead>
        <tbody>
          {folders && folders.map((folderDetails) => (
            <tr>
              <td onClick={() => openFolderDetails(folderDetails.id, folderDetails.name)}>
                <FontAwesomeIcon icon={faFolder} style={{ color: "#74C0FC", paddingRight: "5px" }} />
                <span  >{folderDetails.name}</span> </td>

              <td>{folderDetails.fileCount}</td>
              <td>{formatDateTime(folderDetails.createdAt)}</td>

            </tr>
          ))
          }
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        TotalPage={TotalPage}
        currentSet={currentSet}
        setCurrentSet={setCurrentSet}
        setcurrentPage={setcurrentPage}
      />

    </div>
  )
}

export default Documents_folder
