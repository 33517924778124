
import axios from 'axios';
import Cookies from 'js-cookie';
const axiosInstance = axios.create({
    
    // headers: {
    //     'Content-Type': 'application/json'
    // }
});


// Add an interceptor for all requests

axiosInstance.interceptors.request.use(config => {
const admoneCookie = Cookies.get('admone_cp');

    if (admoneCookie) {
        try {
          
            const tokens = JSON.parse(admoneCookie);
            const accessToken = tokens.accessToken;

            if (accessToken) {
               
                config.headers.Authorization = `Bearer ${accessToken}`;
            }
        } catch (error) {
            console.error('Failed to parse admone cookie:', error.message);
        }
    } else {
        console.log('No admone cookie found');
    }

    return config;
}, error => {
   
    return Promise.reject(error);
});





export default axiosInstance;