import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/chat`;
const getThread = async (data) => {

    const res = await axiosInstance.post(API_URL + "/threads",data)

    return res.data;

}


const getMessage=async(Threadid,data)=>{
    const res = await axiosInstance.get(API_URL + `/thread/${Threadid}/getChats`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;  
}




const SendNewMessage = async (Threadid,data) => {
   
    const res = await axiosInstance.post(API_URL + `/thread/${Threadid}/sendNewMessage`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}


const updateTask = async (Threadid,data) => {
   
    const res = await axiosInstance.patch(API_URL +`/thread/${Threadid}/updateTask`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}


const createNewThread = async (data) => {
   
    const res = await axiosInstance.post(API_URL +`/createNewThread`,data,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}
const getdashboardstats = async () => {
   
    const res = await axiosInstance.get(API_URL +`/getDashboardStats`,{
        headers: {
          'Content-Type': 'application/json',
        },})

    return res.data;

}

const chatService={
    getThread,SendNewMessage,updateTask,getMessage,createNewThread,getdashboardstats
  

}
export default chatService