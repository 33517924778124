import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  folderPath: [], // Folder path [{ id, name }, ...]
  currentFolderId: null, // Active folder ID

  activeClient: null,
};

const folderSlice = createSlice({
  name: "folder",
  initialState,
  reducers: {
    setFolderPath: (state, action) => {
      state.folderPath = action.payload;
    },
    appendToFolderPath: (state, action) => {
      state.folderPath.push(action.payload); // Add new folder to path
      state.currentFolderId = action.payload.id; // Set current folder ID
    },
    removeFromFolderPath: (state, action) => {
      state.folderPath = state.folderPath.slice(0, action.payload + 1);
      state.currentFolderId =
        state.folderPath[state.folderPath.length - 1]?.id || null;
    },
    resetFolderPath: (state) => {
      state.folderPath = [];
      state.currentFolderId = null;
    },
    setActiveClient: (state, action) => {
     
      state.activeClient = action.payload;
    },
    resetActiveClient: (state) => {
      state.activeClient = null;
    },
  },
});

export const {
  setFolderPath,
  appendToFolderPath,
  removeFromFolderPath,
  resetFolderPath,
  setActiveClient,
  resetActiveClient
} = folderSlice.actions;

export default folderSlice.reducer;
