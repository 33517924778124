import React, { useState } from 'react';
import { Table, Badge } from 'react-bootstrap';
import { getFileIcon } from '../../utils/fileIcon';
import { folderIcon, trashIcon } from '../../utils/icons';
import PreviewFileModal from '../../components/common/Filemanager_PreviewFileModal';
import { downloadFile } from '../../utils/downloadFiles';
import Pagination from '../../components/common/Pagination';
import { setLoading } from '../../feature/loading.slice';
import { setMessage } from '../../feature/message.slice';
import messages from '../../utils/meesage';
import { useDispatch, useSelector } from 'react-redux';
import { appendToFolderPath } from '../../feature/folderPath.slice';
import FilesService from '../../services/files.service';

const Documents_files = ({
  responseData, 

  currentPage,
  TotalPage,
  currentSet,
  setCurrentSet,
  setcurrentPage,
  getFiles,
  setSortDirection,
  setSortBy,
  sortBy,
  sortDirection,
  getFolderDetails,
  setActivate,
  folderDetails,
}) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [fileBlob, setFileBlob] = useState(null);
 const currentFolderId = useSelector((state) => state.folder.currentFolderId);
  const [fileType, setFileType] = useState(null);
  const [approved, setapproved] = useState(false);
  const [approvedShow, setapprovedShow] = useState(false);
  const [FileId, setFileId] = useState("")
  const downloadExtensions = ['docx', 'xlsx', 'pptx'];
  const dispatch = useDispatch();

  const handleShow=async(file)=>{
    try{
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));
    const res= await FilesService.filePreview(file.id)
      
        if (res) {
        
        const blob = res.data;
        const blobUrl=URL.createObjectURL(blob)
        console.log("blobUrl",blobUrl)
        setFileBlob(blobUrl); 
        setFileType(res.headers['content-type'])
        if (downloadExtensions.includes(file.file_type) &&blobUrl ){
         downloadFile(blobUrl, file.file_name);
         }else{
        setShow(true);
        dispatch(setLoading(false));
      }
      } else {
        console.error('Failed to fetch file');
      }
    }catch(err){
      setFileBlob(null)
      setShow(true);
      console.log(err)
    
    }
    
    
    
    
    }


    const uploadViewhandleShow =async (files) => {
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));
      if (files.status == "approved" || files.status == "rejected") {
        setapproved(false)
      } else {
        setapproved(true)
      }
  
  
  
      setFileId(files.id)
      setapprovedShow(true)
      setShow(true);
  
      const res= await FilesService.filePreview(files.id)
   if (res) {
    const blob = res.data;
      const blobUrl=URL.createObjectURL(blob)
      setFileBlob(blobUrl); 
      setFileType(res.headers['content-type'])
      dispatch(setLoading(false));
    }
  }




const handleapprovedFile = async (status) => {
    const data = {
      status: status
    }
    try {
      await FilesService.approvedFile(FileId, data)
    
      // getFiles()
    } catch (err) {
      console.log(err)
    }
  }

const handleSort = (column) => {
    const newDirection = sortBy === column && sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortBy(column);
    setSortDirection(newDirection);
  };



  const ucfirst = (str) =>
str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';



    
const handleDelete = async (id) => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));
      await FilesService.DeleteFile(id);
      getFiles(currentFolderId,null);
      // getTrashedFile();
      dispatch(setMessage(messages.success.FILE_DELETE));
    } catch (err) {
      console.log(err);
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };


  return (
    <div className="documents_files">
      <div className="table-responsive">
        <Table hover responsive>
          <thead>
            <tr>
              <th onClick={() => handleSort('file_name')}>
                Name {sortBy === 'file_name' && (sortDirection === 'ASC' ? '↑' : '↓')}
              </th>
              <th>Type</th>
              <th>Created By</th>
              <th>Company name</th>
                  <th>Created On</th>
              <th>Status</th> {/* Added Status column */}
              <th className="text-center">Trash</th>
            </tr>
          </thead>
          <tbody>
            {/* Render folders */}
            {responseData.subfolders.data &&
              responseData.subfolders.data.map((folder) => (
            
                <tr key={`folder-${folder.id}`}>
                  <td onClick={() => folderDetails(folder.id, folder.name,folder.company_id,folder.company_name)}>
             

                    <div className="d-flex align-items-center">
                      <span className="icon">
                        <img src={folderIcon} className="file-icon me-2" alt="Folder" />
                      </span>
                      <span role="button">{folder.name}</span>
                    </div>
                  </td>
                  <td>Folder</td>
                  <td>{folder.created_by || '-'}</td>
                  <td>{folder.company_name || '-'}</td>

                  <td>{folder.uploaded_on || '-'}</td>
                  <td>-</td> {/* No status for folders */}
                  <td className="text-center">
                    {/* <div>
                      <img
                        role="button"
                        src={trashIcon}
                        alt="Trash"
                        onClick={() => handleDelete(folder.id)}
                      />
                    </div> */}
                  </td>
                </tr>
              ))}

            {/* Render files */}
            {responseData.files.data &&
              responseData.files.data.map((file) => (
                <tr key={`file-${file.id}`}>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="icon">
                        <img src={getFileIcon(file.file_name.split('.').pop())} className="file-icon me-2" alt="File" />
                      </span>
                      <span role="button" onClick={() => handleShow(file)}>
                        {file.file_name}
                      </span>
                    </div>
                  </td>
                  <td>File</td>
                  <td>{file.uploaded_by || '-'}</td>
                  <td>{file.company_name || '-'}</td>

                  <td>{file.uploaded_on || '-'}</td>
                  <td>
                    {file.status === 'pending' ? (
                      <Badge pill bg="dark" onClick={() => uploadViewhandleShow(file)}>
                        View & Approve
                      </Badge>
                    ) : (
                      <span
                        className={`badge rounded-pill ${
                          file.status?.toLowerCase() === 'approved' ? 'bg-success' : 'bg-secondary'
                        }`}
                      >
                        {ucfirst(file.status)}
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                   {file.action&& <div>
                      <img
                        role="button"
                        src={trashIcon}
                        alt="Trash"
                        onClick={() => handleDelete(file.id)}
                      />
                    </div>}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
        <div className="d-flex justify-content-end w-100">
          <Pagination
            currentPage={currentPage}
            TotalPage={TotalPage}
            currentSet={currentSet}
            setCurrentSet={setCurrentSet}
            setcurrentPage={setcurrentPage}
          />
        </div>
      </div>
      {show && fileBlob && (
        <PreviewFileModal
          show={show}
          handleClose={handleClose}
          fileUrl={fileBlob}
          fileType={fileType}
          approved={approved}
          approvedShow={approvedShow}
          handleapprovedFile={handleapprovedFile} 
        />
      )}
    </div>
  );
};

export default Documents_files;
