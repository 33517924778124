// src/utils/validation.js

export const validateEmail = (email) => {
    if (!/\S+@\S+\.\S+/.test(email)) {
      return 'Please enter a valid email address';
    }
    return '';
  };
  
  export const validatePassword = (password) => {
    if (password.length < 6) {
      return 'Password must be at least 6 characters long';
    }
    return '';
  };

  export const resetPassword=({password,confirmPassword})=>{
    const errors = {};
    const isValidPassword = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(password);
    const isMatchingPassword = password == confirmPassword;
    if(!isValidPassword){
// return "Password must be at least 8 characters long and include at least one number and one symbol"
errors.password="Password must be at least 8 characters long and include at least one number and one symbol"
    }

    if(!isMatchingPassword){
      // return "Passwords do not match"
      errors.confirmPassword="Passwords do not match"
    }
return errors

  }
  



  export const validateOtp = (otp) => {
    if (otp.length !== 6 || !/^\d*$/.test(otp)) {
      return 'OTP must be 6 digits';
    }
    return '';
  };
  
  export const validateForm = ({ email, password }) => {
    const errors = {};
  
    const emailError = validateEmail(email);
    if (emailError) {
      errors.email = emailError;
    }
  
    const passwordError = validatePassword(password);
    if (passwordError) {
      errors.password = passwordError;
    }
  
    return errors;
  };


  
  export const validateFileUpload = (selectedClients,files) => {
    const errors = {};
  
    if (!selectedClients) {
      errors.client = 'Please select a client ';
    } 
    
    if (files.length === 0) {
      errors.files  = 'Please select at least one file.';
    }
    
    
 
  
    return errors;
  };




  export const MAX_FILE_SIZE_MB = 5; // Maximum file size in MB
  export const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'png', 'xlsx', 'docx', 'pptx'];
  
  export const validateFiles = (files) => {
    const errors = [];
  
    Array.from(files).forEach((file) => {
      const fileExtension = file.name.split('.').pop().toLowerCase();
      const fileSizeMB = file.size / (1024 * 1024); // Convert file size to MB
  
      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        errors.push({
          fileName: file.name,
          reason: `File size exceeds ${MAX_FILE_SIZE_MB} MB`,
        });
      }
  
      if (!allowedExtensions.includes(fileExtension)) {
        errors.push({
          fileName: file.name,
          reason: 'Invalid file format',
        });
      }
    });
  
    return errors;
  };
  



  