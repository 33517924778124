import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/client-portal/v1/`;
const getTaxAccount = async (id ) => {

    const res = await axios.get(API_URL + "tax-accounts/"+id)

    return res.data;

}
const getTaxAccountType = async () => {

    const res = await axios.get(API_URL + "tax-accounts-type")

    return res.data;

}
const TaxAccountService={
    
    getTaxAccount,
    getTaxAccountType

}

export default TaxAccountService