import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Default from '../../components/layouts/default';
import ClientService from '../../services/client.service';
import { setClientId } from '../../feature/client.slice';
import ScoreCard from './ScoreCard';
import RecentThreads from './RecentThreads';
import ListCard from './ListCard';
import { fetchAllThreads } from '../../feature/chat.slice';
import chatService from '../../services/chat.service';

const Dashboard = () => {
  const dispatch = useDispatch();

  const userId = localStorage.getItem('userId');
  const client = localStorage.getItem('client');
  const clientIds = JSON.parse(client);
  const [userName, setUserName] = useState("");

  const [Threads, setThreads] = useState([]);
  const [files,setfiles]=useState([])
  const [notDoneTask,setnotDoneTask]=useState("")
  const [unreadCount,setunreadCount]=useState("")
  const [TotalTask,setTotalTask]=useState("")


    const [currentPage, setCurrentPage] = useState(1);


    const fetchThreads = async () => {
    
    
   

        try {
            const threads = await chatService.getdashboardstats();
           
        
            setThreads(threads.threads);
            setfiles(threads.recentFiles)
            setnotDoneTask(threads.pendingTask)
            setunreadCount(threads.unreadMessageCount)
            setTotalTask(threads.totalTask)
        } catch (err) {
            setThreads([]);
            console.log(err);
        }

    };


  useEffect(() => {

    const storedUserName = localStorage.getItem('userName');
    setUserName(storedUserName);
    fetchThreads();
  }, [ dispatch,client]);


  const documents = [
    { description: "SalesTax-Report-Mar.doc", icon: 'word', timestamp: "2 Days ago By Admin" },
    { description: "SalesTax-Report-Mar.pdf", icon: 'pdf', timestamp: "2 Days ago By Admin" },
    { description: "SalesTax-Report-Mar.doc", icon: 'word', timestamp: "2 Days ago By Admin" },
    { description: "SalesTax-Report-Mar.pdf", icon: 'pdf', timestamp: "2 Days ago By Admin" },
    { description: "SalesTax-Report-Mar.pdf", icon: 'pdf', timestamp: "2 Days ago By Admin" },
    { description: "SalesTax-Report-Mar.pdf", icon: 'pdf', timestamp: "2 Days ago By Admin" },
    { description: "SalesTax-Report-Mar.doc", icon: 'word', timestamp: "2 Days ago By Admin" },
  ];

  return (
    <Default 
      className="dashboard"
      pageTitle={`Welcome Back, ${userName}`} 
      pageSubTitle="Manage all your accounting needs seamlessly with the ADM ONE Portal, designed to simplify your financial workflow."
    >
     
      <div className="row">
        <div className="col-md-8 col-sm-12 mb-3 mb-md-0">
          <div className="scorecards_container mb-4">
          <ScoreCard
              title="Unread Chats"
              // subtitle="Unread vs All"
              value={unreadCount || 0}
              // valueCompare="32"
              textColor="text-dark"
            />
            <ScoreCard
              title="Pending Tasks"
              subtitle="Pending vs All"
              value={notDoneTask || 0}
              valueCompare={TotalTask}
              textColor="text-success"
            />
            {/* <ScoreCard
              title="Payment Due"
              value="$898"
              textColor="text-primary"
            /> */}
          </div>

          <RecentThreads  Threads={Threads || []} />
        </div>
        <div className='col-md-4 col-sm-12 '>
          <ListCard title="Recent Documents" files={files}/>
        </div>
      </div>
    </Default>
  );
};

export default Dashboard;
