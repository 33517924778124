// import React from "react";

// const BreadcrumbNavigation = ({
//   folderPath = [],
//   activeClient,
//   handleResetPath,
//   handleBreadcrumbClick,
// }) => {
//   // 1. If folderPath is empty, or exactly one item = {id:0, name:"Home"}, we are at the root => show nothing
//   if (
//     folderPath.length === 0 ||
//     (folderPath.length === 1 &&
//       folderPath[0].id === 0 &&
//       folderPath[0].name === "Home")
//   ) {
//     return null;
//   }

//   // 2. If the first item is NOT { id:0, name:"Home" }, prepend it
//   //    because we want the breadcrumb to start with "Home"
//   let breadcrumbItems = folderPath;
//   const first = folderPath[0];
//   const isFirstHome = first?.id === 0 && first?.name === "Home";
//   if (!isFirstHome) {
//     breadcrumbItems = [{ id: 0, name: "Home" }, ...folderPath];
//   }

//   // 3. Render the breadcrumb
//   return (
//     <nav className="breadcrumb mb-3">
//       {breadcrumbItems.map((crumb, index) => (
//         <span
//           key={`${crumb.id}-${index}`}
//           role="button"
//           className="breadcrumb-item"
//           onClick={() => handleBreadcrumbClick(index)}
//         >
//           {crumb.name}
//         </span>
//       ))}
//     </nav>
//   );
// };

// export default BreadcrumbNavigation;


import React from "react";

const BreadcrumbNavigation = ({
  folderPath = [],
  activeClient,
  handleBreadcrumbClick,
}) => {
  // Build a single crumb array:
  const crumbs = [];

  // 1) Always "Home" as crumb #0
  crumbs.push({ id: "home", name: "Home" });

  // 2) If there's an activeClient, add it as crumb #1
  if (activeClient) {
    crumbs.push({ id: activeClient.id, name: activeClient.name });
  }

  // 3) Possibly skip the first folder if it duplicates the client name
  let skipIndex = 0;
  if (
    folderPath.length > 0 &&
    activeClient &&
    folderPath[0].name === activeClient.name
  ) {
    skipIndex = 1;
  }

  // 4) Append remaining folders
  for (let i = skipIndex; i < folderPath.length; i++) {
    crumbs.push(folderPath[i]);
  }

  // Render all crumbs (including "Home")
  return (
    <nav className="breadcrumb mb-3">
      {crumbs.map((crumb, index) => (
        <span
          key={`${crumb.id}-${index}`}
          role="button"
          className="breadcrumb-item"
          onClick={() => handleBreadcrumbClick(index, skipIndex)}
        >
          {crumb.name}
        </span>
      ))}
    </nav>
  );
};

export default BreadcrumbNavigation;
