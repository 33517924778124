import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Form, Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import ClientService from '../../services/client.service';
import logo from "../../assets/images/adm_logo.png";
import smalllogo from "../../assets/images/adm_logo_small.png";
import {  dashboardIcon, documentIcon, dragIcon, chatIcon, billingIcon, companydataIcon, } from '../../utils/icons'; 

import { setClient } from '../../feature/client.slice';


const Sidebar = ({ isCollapsed, toggleSidebar,resetSelectedThread  }) => {
  const [showSidebar, setShowSidebar] = useState(false);
  const [activeItem, setActiveItem] = useState('dashboard');
  const [show, setShow] = useState(false);
  // const [userId, setUserId] = useState('');
  const [clientDetails, setClientDetails] = useState([]);
  // console.log(clientDetails)
  const [mainDetails, setMainDetails] = useState([]);

  const dispatch = useDispatch();
  const location = useLocation();
  const pathArray = window.location.pathname.split('/');
  const id = pathArray[pathArray.length - 1];



  const handleItemClick = (item) => {
   
    setActiveItem(item)
  if(item=="taskandchats" && resetSelectedThread ){
    resetSelectedThread() 
  }
  
  };
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCheckboxChange = (clientId) => {
    const selectedClient = clientDetails.find(client => client.id === clientId);
    setMainDetails(selectedClient);

  };

  useEffect(() => {
    // Update the active item based on the current location
    const pathArray = location.pathname.split('/');
    const currentPath = pathArray[pathArray.length - 1];
    setActiveItem(currentPath);
  }, [location.pathname]);

 
  const navItems = [

    { name: 'Dashboard', path: `/dashboard`, key: 'dashboard', imageicon: dashboardIcon },
    { name: 'Documents', path: '/documents',  key: 'documents', imageicon: documentIcon },
    { name: 'Task & Chats', path: '/chats',  key: 'taskandchats', imageicon: chatIcon },
    { name: 'Company Data', path: '/CompanyData',  key: 'CompanyData', imageicon: companydataIcon },
    // { name: 'Billing', path: '/BillingCustom',  key: 'BillingCustom', imageicon: billingIcon }

  ];

  const handleToggleSidebar = () => {
    toggleSidebar(!isCollapsed);
    localStorage.setItem('sidebarState', !isCollapsed ? 'collapsed' : 'expanded');
  };

  return (
    <>
  <div className={`side-bar ${isCollapsed ? 'collapsed' : 'expanded'}`}>
        <div className='logo-container'>
          <a className='logo' href="/" ><img src={isCollapsed ? smalllogo : logo} alt="Logo" /></a>
          <div className={`toggle-icon ${isCollapsed ? 'collapsed' : ''}`}> 
            <img src={dragIcon} onClick={handleToggleSidebar} />
          </div>
        </div>
        <nav>
          <ul>
            {navItems.map(item => (
              <li key={item.key}>
                <Link
                  to={item.path}
                  className={`link-list ${location.pathname.includes(item.path) ? 'active' : ''}`}
                  onClick={() => handleItemClick(item.key)}
                >
                  <div className='icon-label'>
                    <img
                      src={item.imageicon}
                      width="20"
                      height="20"
                      className="icon"
                    />
                    {!isCollapsed && <span className="nav-link">{item.name}</span>}
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
     
      {/* BOOTSTRAP MODAL */}
      <Modal show={show} onHide={handleClose} animation={true}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="SwitchCompany_wrapper p-2">
            {clientDetails && clientDetails.map((clientDetails) => (
              <div className="mb-4" key={clientDetails.id}>
                <div className="d-flex justify-content-between align-items-top">
                  <div className='d-flex'>
                    <div className='me-3'>
                      <img src={logo} alt="" className="w-100" />
                    </div>
                    <div>
                      <p className="card-title m-0 text-black fw-semibold">{clientDetails.name}</p>
                      <p className="card-text text-black" style={{ fontSize: "14px" }}>
                        {clientDetails.address_line_1}, {clientDetails.zip}<br />{clientDetails.email}
                      </p>
                    </div>
                  </div>
                  <Form.Check type="radio" id={`css1_${clientDetails.id}`} name="fav_language" value="CSS" onChange={() => handleCheckboxChange(clientDetails.id)} />
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Sidebar;
