import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/client-portal/v1/`;
const getBank = async (id ) => {

    const res = await axios.get(API_URL + "banks/"+id)

    return res.data;

}
const BankService={
    
    getBank

}

export default BankService