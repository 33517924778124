import React, { useEffect } from 'react'
import { Row, Col, Card, Accordion, } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import frame from '../../../assets/images/eye_icon.png'
import LoginService from '../../../services/logins.service';
import { useSelector } from 'react-redux';


const CompanyDataLogin = ({clientId}) => {


  const validationSchema = Yup.object().shape({

    userid: Yup.number().required('User id is required'),
    password: Yup.number().required('Password is required'),
    companyid: Yup.number().required('Company Id is required'),
    notes: Yup.string().required('Notes is required'),



  });
  const [showAddpopup, setAddpopup] = useState(false);
  const [showViewPopup, setViewPopup] = useState(false);
  const handleClose = () => setAddpopup(false);
  const handleShow = () => setAddpopup(true);
  const viewHandleClose = () => setViewPopup(false);
  const viewHandleShow = (LoginsDetails) => {
    
    setLoginsAllDetails(LoginsDetails)
    
    
    setViewPopup(true)};

  const[LoginsDetails,setLoginsDetails]=useState([])
  const [LoginsAllDetails,setLoginsAllDetails]=useState([])

  // const clientId = useSelector((state) => state.client.clientId);
  const fetchLogins=async()=>{

    try{
  
      const LoginsDetails=await LoginService.getLogins(clientId)
     
      setLoginsDetails(LoginsDetails)
  
    }catch(err){
    console.log(err)
    }
    
    }



    useEffect(()=>{
      fetchLogins()
    
    },[clientId])


  return (
    <>

{ LoginsDetails.length!=0&&<Card className="p-4 col-lg-3 col-sm-12 " >

      <div>
        <div className="d-flex justify-content-between py-1">
          <h5 className="fw-bold" style={{ color: "#232D42" }}>Logins</h5>
          {/* <Button variant="outline-primary" className='py-2 btn-sm' onClick={handleShow}>ADD NEW</Button> */}
        </div>
        <div style={{ borderTop: '2px solid #E9ECEF', width: '100%', margin: '20px 0' }}><div>
        { LoginsDetails&&LoginsDetails.map((LoginsDetails)=>(
          <div className="d-flex justify-content-between py-3">
            <div style={{ width: '70%' }}>
              <h5 className="fs-6 mb-1">{LoginsDetails.user_id}</h5>
              <p className='text-black mb-0' >A/c No: 81006</p>
            </div>
            <div style={{ width: '10%' }}>
              <img src={frame} alt="User-Profile" onClick={()=>viewHandleShow(LoginsDetails)} />
            </div>
          </div>
        )) }
    
        </div>
        </div>
      </div>
      </Card>}
     

      <Modal show={showViewPopup} onClick={viewHandleClose} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>View Login Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className=" py-2 px-3">
           
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>User id</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{LoginsAllDetails.user_id}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Password {LoginsAllDetails.password} </span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{}</p>
              </div>
            </div>

            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Company id</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{LoginsAllDetails.company_id}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Login type</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >Chooes one</p>
              </div>
            </div>

            <div className="d-flex justify-content-start mb-4">
              <div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}>Notes</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{LoginsAllDetails.notes}</p>
              </div>             
            </div>

          </div>
        </Modal.Body>
      </Modal>









    </>






  )
}

export default CompanyDataLogin