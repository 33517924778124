import React, { useState, useEffect } from "react";
import Default from "../../components/layouts/default";
import { Container, Row, Col, Form, Button, Card } from "react-bootstrap";
import InputMask from "react-input-mask";
import {
  changePassword,
  getLastLogin,
  getProfilePictureBlob,
  updatePhoneNumber,
  uploadProfilePicture,
  getUserDetails,
} from "../../services/profile.service";
import { useDispatch } from "react-redux";
import { setLoading } from "../../feature/loading.slice";
import { setMessage } from "../../feature/message.slice";
import messages from "../../utils/meesage";
import LoginAuth from "../../services/auth.service";
import { useNavigate } from "react-router-dom";
import Error from "../../components/common/Error";
import Success from "../../components/common/Success";

const MAX_FILE_SIZE_MB = 1; // Define the maximum file size

const Profile = () => {
  const [userDetails, setUserDetails] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [lastLogin, setLastLogin] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [preview, setPreview] = useState(null); // Stores selected image
  const [errorPhone, setErrorPhone] = useState(""); // Error message for phone update
  const [successPhone, setSuccessPhone] = useState(""); // Success message for phone update
  const [errorPassword, setErrorPassword] = useState(""); // Error message for password update
  const [successPassword, setSuccessPassword] = useState(""); // Success message for password update
  const [errorPicture, setErrorPicture] = useState(""); // Error message for profile picture
  const [successPicture, setSuccessPicture] = useState(""); // Success message for profile picture
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchUserDetails = async () => {
    try {
      dispatch(setLoading(true));
      const data = await getUserDetails();
      setUserDetails(data.user); // Populate user details dynamically
      dispatch(setLoading(false));
    } catch (error) {
      console.error("Error fetching user details:", error);
    }
  };

  useEffect(() => {
    const fetchLastLogin = async () => {
      try {
        const data = await getLastLogin(userDetails.id);
        setLastLogin(data.lastLogin);
      } catch (error) {
        console.error("Error fetching last login:", error);
      }
    };

    const fetchProfilePicture = async () => {
      try {
        const blob = await getProfilePictureBlob(userDetails.id);
        const url = URL.createObjectURL(blob); // Convert blob to a URL
        setPreview(url); // Set the preview URL for the image
      } catch (error) {
        console.error("Error fetching profile picture:", error);
      }
    };

    fetchUserDetails();
    fetchLastLogin();
    fetchProfilePicture();
  }, [userDetails.id, dispatch]);

  // Clear messages after 10 seconds
  const clearMessages = () => {
    setTimeout(() => {
      setErrorPhone("");
      setSuccessPhone("");
      setErrorPassword("");
      setSuccessPassword("");
      setErrorPicture("");
      setSuccessPicture("");
    }, 10000);
  };

  // Handle phone number update
  const handleUpdatePhoneNumber = async () => {
    try {
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));
      if (!userDetails.phone.trim()) {
        setErrorPhone("Please enter a valid mobile number.");
        setSuccessPhone("");
        clearMessages();
        return;
      }

      await updatePhoneNumber(userDetails.id, userDetails.phone);
      setSuccessPhone("Phone number updated successfully.");
      setErrorPhone("");
      fetchUserDetails();
      dispatch(setMessage(messages.success.PHONE_UPDATE));
      clearMessages();
    } catch (error) {
      setErrorPhone("Error updating phone number.");
      setSuccessPhone("");
      clearMessages();
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  // Handle password change
  const handleChangePassword = async () => {
    try {
     
      if (!password.trim() || !confirmPassword.trim()) {
        setErrorPassword("Password and Confirm Password fields cannot be empty.");
        setSuccessPassword("");
        clearMessages();
        return;
      }

      if (password !== confirmPassword) {
        setErrorPassword("Passwords do not match!");
        setSuccessPassword("");
        clearMessages();
        return;
      }

      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));

      await changePassword(userDetails.id, password, confirmPassword);
      setSuccessPassword("Password changed successfully.");
      setErrorPassword("");
      fetchUserDetails();
      dispatch(setMessage(messages.success.PASSWORD_RESET_SUCCESS));
      clearMessages();
    } catch (error) {
      setErrorPassword("Error changing password.");
      setSuccessPassword("");
      clearMessages();
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
        setErrorPicture(`File size cannot exceed ${MAX_FILE_SIZE_MB} MB.`);
        setSuccessPicture("");
        clearMessages();
        return;
      }
      setProfilePicture(file);
      setPreview(URL.createObjectURL(file)); // Generate a preview URL
    }
  };

  const handleUploadProfilePicture = async () => {
    try {
      if (!profilePicture) {
        setErrorPicture("Please select a profile picture first.");
        setSuccessPicture("");
        clearMessages();
        return;
      }
      dispatch(setLoading(true));
      dispatch(setMessage(messages.loading));
      const formData = new FormData();
      formData.append("file", profilePicture);

      await uploadProfilePicture(formData);
      setSuccessPicture("Profile picture updated successfully.");
      setErrorPicture("");
      fetchUserDetails();
      dispatch(setMessage(messages.success.SAVE_FILE));
      clearMessages();
    } catch (error) {
      setErrorPicture("Error uploading profile picture.");
      setSuccessPicture("");
      clearMessages();
    } finally {
      setTimeout(() => {
        dispatch(setLoading(false));
      }, 3000);
    }
  };

  const logout = async () => {
    await LoginAuth.logout();
    navigate("/");
  };

  return (
    <Default pageTitle="Profile">
      <Container className="mt-4">
        {/* User Info Section */}
        <Row className="mb-4">
          <Col>
            <Card className="shadow-sm bg-white rounded">
              <Card.Body>
                <Row className="d-flex justify-content-between align-items-center">
                  <Col>
                    <h4 className="mb-0">
                      {userDetails.name} {userDetails.surname}
                    </h4>
                    <p className="text-muted mb-0">
                      Logged in with <strong>{userDetails.email}</strong>
                    </p>
                  </Col>
                  <Col className="text-end">
                    <p className="text-muted mb-1">
                      Last Logged in: {lastLogin || "Fetching..."}
                    </p>
                    <Button variant="outline-danger" size="sm" onClick={logout}>
                      Logout
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {/* Cards Section */}
        <Row className="d-flex align-items-stretch">
          {/* Update Contact Info */}
          <Col md={4} className="d-flex">
            <Card className="flex-fill shadow-sm">
              <Card.Body className="d-flex flex-column">
                <Card.Title>Update Contact Info</Card.Title>
                <Form>
                  <Form.Group controlId="mobileNumber" className="mb-3">
                    <Form.Label>Mobile Number</Form.Label>
                    <InputMask
                      mask="(999) 999-9999"
                      className="form-control"
                      placeholder="Enter mobile number"
                      value={userDetails.phone || ""}
                      onChange={(e) =>
                        setUserDetails({ ...userDetails, phone: e.target.value })
                      }
                    />
                  </Form.Group>
                </Form>
                <Button
                  variant="primary"
                  className="w-100 mt-auto"
                  onClick={handleUpdatePhoneNumber}
                >
                  Update
                </Button>
                <Error error={errorPhone} />
                <Success message={successPhone} />
              </Card.Body>
            </Card>
          </Col>

          {/* Change Password */}
          <Col md={4} className="d-flex">
            <Card className="flex-fill shadow-sm">
              <Card.Body className="d-flex flex-column">
                <Card.Title>Change Password</Card.Title>
                <Form>
                  <Form.Group controlId="password" className="mb-3">
                    <Form.Label>Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Enter new password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>
                  <Form.Group controlId="confirmPassword" className="mb-3">
                    <Form.Label>Confirm Password</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Confirm new password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>
                </Form>
                <Button
                  variant="primary"
                  className="w-100 mt-auto"
                  onClick={handleChangePassword}
                >
                  Change Password
                </Button>
                <Error error={errorPassword} />
                <Success message={successPassword} />
              </Card.Body>
            </Card>
          </Col>

          {/* Upload Profile Picture */}
          <Col md={4} className="d-flex">
            <Card className="flex-fill shadow-sm">
              <Card.Body className="d-flex flex-column text-center">
                <Card.Title>Upload Profile Picture</Card.Title>
                <div className="mb-3">
                  {preview ? (
                    <img
                      src={preview}
                      alt="Profile Preview"
                      className="rounded-circle"
                      style={{ width: "100px", height: "100px" }}
                    />
                  ) : (
                    <div
                      className="rounded-circle bg-light"
                      style={{
                        width: "100px",
                        height: "100px",
                        display: "inline-block",
                        lineHeight: "100px",
                        textAlign: "center",
                        color: "#ccc",
                      }}
                    >
                      100x100
                    </div>
                  )}
                </div>
                <Form>
                  <Form.Group controlId="profilePicture" className="mb-3">
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleProfilePictureChange}
                    />
                  </Form.Group>
                </Form>
                <Button
                  variant="primary"
                  className="w-100 mt-auto"
                  onClick={handleUploadProfilePicture}
                >
                  Change Pic
                </Button>
                <Error error={errorPicture} />
                <Success message={successPicture} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </Default>
  );
};

export default Profile;
