import React, { useEffect } from 'react'
import { useState, useRef } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import BankService from '../../../services/bank.service';
import { Card } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';

const BankDetails = ({ banksDetails }) => {

  const validationSchema = Yup.object().shape({
    bankname: Yup.string().required('Name is required'),
    routingno: Yup.string().required('Routing No is required'),
    Website: Yup.string().required('Website is required'),
    streetName: Yup.string().required('Street Name & Number is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.number().required('Zip is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.number().required('State is required'),
    accountno: Yup.number().required('Account No is required'),
    Password: Yup.number().required('Password is required'),
    notes: Yup.string().required('Country is required')
  });
  const [showAddpopup, setAddpopup] = useState(false);
  const [showViewPopup, setViewPopup] = useState(false);
  const [BankDetails, setBankDetails] = useState([])
  // console.log(BankDetails)
  const [BankAllDetails, setBankAllDetails] = useState([])
  // console.log(BankAllDetails)

  const [visibleAnswers, setVisibleAnswers] = useState({});


  const toggleAnswerVisibility = (index) => {
    setVisibleAnswers((prevVisibleAnswers) => ({
      ...prevVisibleAnswers,
      [index]: !prevVisibleAnswers[index],
    }));
  };



  const handleClose = () => setAddpopup(false);
  const handleShow = () => setAddpopup(true);
  const viewHandleClose = () => setViewPopup(false);
  const viewHandleShow = (bankDetails) => {
    setBankAllDetails(bankDetails)


    setViewPopup(true)
  };


  if (BankAllDetails.secret_questions_answers) {
    var secretQuestions = JSON.parse(BankAllDetails && BankAllDetails.secret_questions_answers);
  }

  // const fetchBankDetails=async()=>{

  // try{
  // console.log(clientId)
  //   const BankDetails=await BankService.getBank(clientId)




  // setBankDetails(BankDetails)
  //   // console.log(BankDetails)
  // }catch(err){
  // console.log(err)
  // }

  // }
  // useEffect(()=>{
  // fetchBankDetails()
  // },[clientId])

  const [showPassword, setShowPassword] = useState(false);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      {banksDetails.length != 0 &&

        <Card className="moreinfo-card">
          <div>
            <div className="d-flex justify-content-between py-1">
              <h5 className="fw-bold card-title">
                <FontAwesomeIcon icon={faUniversity} className="card-icon" /> Banks
              </h5>
            </div>
            <div className="card-divider">
              {banksDetails && banksDetails.map((bankDetails) => (
                <div className="d-flex justify-content-between py-3 card-details" key={bankDetails.account_no}>
                  <div className="card-info">
                    <h6 className="mb-1">Bank Name:</h6>
                    <p className="text-black mb-0">{bankDetails.name}</p>
                  </div>
                  <div className="card-info">
                    <h6 className="mb-1">Account Number:</h6>
                    <p className="text-black mb-0">{bankDetails.account_no}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Card>


      }


      {/* <Modal show={showViewPopup} className='modal-lg'>
        <Modal.Header closeButton  onClick={viewHandleClose}>
          <Modal.Title>View Banks Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className=" py-5 px-3">
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>BANK NAME</span>
              <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{BankAllDetails.name}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >ACCOUNT NUMBER</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{BankAllDetails.account_no}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
          { BankAllDetails.routing_no&&   <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >ROUTING NUMBER</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{BankAllDetails.routing_no}</p>
              </div>}
            {BankAllDetails.website&&  <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >WEBSITE</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }} >{BankAllDetails.website}</p>
              </div>}
            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >USER ID</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{BankAllDetails.user_id}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >PASSWORD</span>
               
                <div className="d-flex align-items-center">
            <p className='fw-semibold text-black mb-0' style={{ fontSize: "18px" }}>
              {showPassword ? BankAllDetails.password : '*******'}
            </p>
            <span onClick={togglePasswordVisibility} style={{ cursor: 'pointer', marginLeft: '10px' }}>
              {showPassword ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
             {BankAllDetails.notes&& <div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}  >NOTES:</span>
                <p className='fw-medium text-black' style={{ fontSize: "20px" }}  >{BankAllDetails.notes}</p>
              </div>}
            </div>
            <div className="d-flex justify-content-start mb-4">
      {  BankAllDetails.name&&<div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}  >BANK NAME</span>
                <p className='fw-medium text-black' style={{ fontSize: "20px" }} >{BankAllDetails.name}</p>
              </div>}
            </div>

          {BankAllDetails.dba!=null&& <div className="d-flex justify-content-start mb-4">
              <div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}  >DBA</span>
                <p className='fw-medium text-black' style={{ fontSize: "20px" }} >{BankAllDetails.dba&&BankAllDetails.dba.name}</p>
              </div>
            </div>}

            <div >
            
         
            <hr className="border border-dark border-1 opacity-50 mb-5" />
            {secretQuestions&&secretQuestions.map((question, index) => (
        <div key={index} className="mb-4 text-black">
          <p className="fs-6 mb-1 text-black">QUESTION {index + 1}:</p>
          <p className="fs-5 mb-1 text-black">{question.secret_questions_answers}</p>
          <p className='text-black'>
            {visibleAnswers[index] ? question.answer : '*******'} 
            <span 
              onClick={() => toggleAnswerVisibility(index)}
              style={{ cursor: 'pointer', color: 'blue', marginLeft: '5px' }}
            >
              (view {visibleAnswers[index] ? 'less' : 'answer'})
            </span>
          </p>
        </div>
      ))}


            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default BankDetails;
