
// const messages = {
//     loading: "Please wait...",
//     dataNotFound: "No data found.",
//     uploading:"Uploading",

//     error: {
//       INVALID_EMAIL: 'Please enter a valid email address.',
//        INVALID_PASSWORD: 'Password must be at least 6 characters long.',
//        RESET_INVALID_PASSWORD: 'Password must be at least 8 characters long and include at least one number and one symbol.',
//        RESET_INVALID_CONFIRM_PASSWORD: 'Passwords do not match.',
//        INVALID_OTP: 'OTP must be 6 digits.',
//        COMPOSE_NEW_MESSAGE:""
//     },
//     success: {
//         LOGIN_SUCCESSFUL: "Login successful, redirecting...",
//         PASSWORD_RESET_SUCCESS: "Password updated successfully",
//         SAVE_FILE:"file save",
//         FILE_DELETE:"File delete",
//         RECOVER_FILE:"File recover",


        
//     }
// };
  
//   export default messages;
  




const messages = {
  loading: "Please wait...",
  dataNotFound: "No data available.",
  uploading: "Uploading...",

  error: {
    INVALID_EMAIL: 'Please enter a valid email address.',
    INVALID_PASSWORD: 'Password must be at least 6 characters long.',
    RESET_INVALID_PASSWORD: 'Password must be at least 8 characters long and include at least one number and one special character.',
    RESET_INVALID_CONFIRM_PASSWORD: 'Passwords do not match.',
    INVALID_OTP: 'OTP must be exactly 6 digits.',
    COMPOSE_NEW_MESSAGE: "Unable to compose a new message."
  },
  success: {
      LOGIN_SUCCESSFUL: "Login successful, redirecting...",
      PASSWORD_RESET_SUCCESS: "Password updated successfully.",
      SAVE_FILE: "File saved successfully.",
      FILE_DELETE: "File deleted successfully.",
      RECOVER_FILE: "File recovered successfully.",
      PHONE_UPDATE:"Phone number update successfully"
  }
};

export default messages;