import axios from 'axios';
const API_URL = `${process.env.REACT_APP_API_URL}/api/client-portal/v1/`;
const getContact = async (id ) => {

    const res = await axios.get(API_URL + "contacts/"+id)

    return res.data;

}
const ContactService={
    
    getContact

}

export default ContactService