import React from 'react'

const View_Approved = ({ selectedFileUrl, approvedFile, approved }) => {
  return (
    <div>
      <div className="card m-0 ">
        <div className="card-body d-flex justify-content-between bg-dark rounded-top-2 ">
          <h5 className="card-title text-white">Payroll Slip 2024March.pdf</h5>
          {!approved && <div className="d-flex gap-2">

            <button type="button" className="btn btn-sm btn-danger">Reject</button>
            <button type="button" className="btn btn-sm btn-success" onClick={approvedFile}>Approve</button>
          </div>}
        </div>
        <div >
          <iframe src={selectedFileUrl} width="100%" height="500px" title="File Viewer" />
        </div>
      </div>
    </div>
  )
}

export default View_Approved
