import React, { useEffect } from 'react'
import { Row, Col, Card, Accordion, } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import DbaService from '../../../services/dba.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding } from '@fortawesome/free-solid-svg-icons'; // Import DBA icon


const CompantDataDBA = ({ dba }) => {

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    streetName: Yup.string().required('Street Name & Number is required'),
    city: Yup.string().required('City is required'),
    zip: Yup.number().required('Zip is required'),
    country: Yup.string().required('Country is required'),
    state: Yup.number().required('State is required')
  });
  const [showAddpopup, setAddpopup] = useState(false);
  const [showViewPopup, setViewPopup] = useState(false);
  const [DbaDetails, setDbaDetails] = useState([])
  const [DbaAllDetails, setDbaAllDetails] = useState([])
  const handleClose = () => setAddpopup(false);
  const handleShow = () => setAddpopup(true);
  const viewHandleClose = () => setViewPopup(false);
  const viewHandleShow = (DbaDetails) => {
    setDbaAllDetails(DbaDetails)



    setViewPopup(true)
  };

  // const clientId = useSelector((state) => state.client.clientId);
  // const fetchDbaDetails=async()=>{

  //   try{

  //     const DbaDetails=await DbaService.getDba(clientId)
  //     setDbaDetails(DbaDetails)
  //     console.log(DbaDetails)
  //   }catch(err){
  //   console.log(err)
  //   }

  //   }


  //   useEffect(()=>{
  //     fetchDbaDetails()
  //   },[clientId])


  return (
    <>
      {dba.length != 0 &&

        <Card className="moreinfo-card">
          <div>
            <div className="d-flex justify-content-between py-1">
              <h5 className="fw-bold card-title">
                <FontAwesomeIcon icon={faBuilding} className="card-icon" /> DBA
              </h5>
            </div>
            <div className="card-divider"></div>
            {dba && dba.map((DbaDetails, index) => (
              <div
                className={`d-flex flex-wrap py-3 card-details ${dba.length === 1 || index === dba.length - 1 ? 'no-border' : ''}`}
                key={DbaDetails.name}
              >
                <div className="card-info half-width">
                  <h6 className="mb-1">Business Name:</h6>
                  <p className="text-black mb-0">{DbaDetails.name}</p>
                </div>
                <div className="card-info half-width">
                  <h6 className="mb-1">Full Address:</h6>
                  <p className="text-black mb-0">{DbaDetails.full_address}</p>
                </div>
              </div>
            ))}
          </div>
        </Card>

      }



      {/* <Modal show={showViewPopup} onClick={viewHandleClose} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Add New DBA</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className=" py-2 px-3">
            <div className="d-flex justify-content-start ">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}>Name</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}>{DbaAllDetails.name}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  ></span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  ></p>
              </div>
            </div>
            <samp className='fs-5 fw-mediumm'> Property Address</samp>
            <hr  className='mt-0'/>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}  >Address</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{DbaAllDetails.address_line_1}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-100">
                <span className='text-black' style={{ fontSize: '15px' }}  >Street Name & Number</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{DbaAllDetails.address_line_2}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >City</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{DbaAllDetails.city}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >Zip</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{DbaAllDetails.zip}</p>
              </div>
            </div>
            <div className="d-flex justify-content-start mb-4">
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >Country</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{DbaAllDetails.country}</p>
              </div>
              <div className="w-50">
                <span className='text-black' style={{ fontSize: '15px' }}  >State</span>
                <p className='fw-semibold text-black' style={{ fontSize: "18px" }}  >{DbaAllDetails.state}</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}

    </>





  )
}

export default CompantDataDBA


