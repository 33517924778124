import axios from 'axios';
import axiosInstance from '../utils/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/companydata`;
const getclient = async (data) => {

    const res = await axiosInstance.post(API_URL,data)

    return res.data;

}

const BusinessActivity = async (code ) => {

    const res = await axios.get(API_URL + "bussiness-activity/"+code)

    return res.data;

}


const BusinessStructure = async (id ) => {

    const res = await axios.get(API_URL + "bussiness-Structure/"+id)

    return res.data;

}


const ClientService={
    
    getclient,BusinessActivity,BusinessStructure

}

export default ClientService