import axiosInstance from '../utils/axiosInstance';
const API_URL = `${process.env.REACT_APP_API_URL}/api/clientportal/v1/profile`;






export const updatePhoneNumber = async (id, phone) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/update_phone_no`, { id, phone });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };
  
  // Change password API call
  export const changePassword = async (id, newPassword, confirmPassword) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/change_password`, {
        id,
        newPassword,
        confirmPassword,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };
  
  // Fetch last login API call
  export const getLastLogin = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/getLastLogin`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };



  export const uploadProfilePicture = async (formData) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/profile_pic`, formData, {
        headers: {
          "Content-Type": "multipart/form-data", // Required for uploading files
         
        },
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };


  export const getProfilePictureBlob = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/profile_pic`, {
     
        responseType: "blob",
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };



  export const getUserDetails = async () => {
    try {
      const response = await axiosInstance.get(`${API_URL}/getLUserDetails`);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  };















