
import React, { useState, useEffect } from 'react';
import Sidebar from '../common/sideBar'
import Header from '../common/CustomHeader/header'
import SubHeader from '../common/CustomHeader/sub-header'
import Footer from '../common/Footer'

import { useSelector } from 'react-redux';
import Loading from '../common/Loading';



const Default = ({ children, pageTitle = "Home", pageSubTitle = "", className = "home", resetSelectedThread }) => {


  const isLoading = useSelector((state) => state.loading.loading);


  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);

  // Load sidebar state from localStorage when the component mounts
  useEffect(() => {
    const sidebarState = localStorage.getItem('sidebarState');
    if (sidebarState) {
      setIsSidebarCollapsed(sidebarState === 'collapsed');
    }
  }, []);

  // Toggle sidebar and save the state to localStorage
  const toggleSidebar = () => {
    const newState = !isSidebarCollapsed;
    setIsSidebarCollapsed(newState);
    localStorage.setItem('sidebarState', newState ? 'collapsed' : 'expanded');
  };


  return (


      <div className={`main ${isSidebarCollapsed ? 'collapsed' : 'expanded'}`}>
      <Sidebar isCollapsed={isSidebarCollapsed} toggleSidebar={toggleSidebar} resetSelectedThread={resetSelectedThread} />
      <Header />
      {isLoading && <Loading/>}
      <div className="main-page">
        <SubHeader pageTitle={pageTitle} pageSubTitle={pageSubTitle} />
        <div className={`container-fluid content ${className}`}>
          {children}
        </div>
        <Footer />
      </div>
    </div>


  )
}

export default Default


















